import { useRef, useState, useEffect, useContext } from "react";
import logopw from "../../assets/img/pw.png";
import { Transition } from "@headlessui/react";
import "react-spinner-animated/dist/index.css";
import { TripleMaze } from "react-spinner-animated";

import UserContext from "../../context/UserContext";

import axios from "axios";
import { localForage } from "../../hooks/useLocalForage";
// import { useLocalForage } from "../../hooks/useLocalForage";
// import localeForage from '../../config/localForage'

export const Login = () => {
    const {
        setUser,
        headers,
        setWorkstationActive,
        url,
        setVersionDb,
        setResourceCache,
        setResourcePagination,
    } = useContext(UserContext);
    const userRef = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [passwordShow, setPasswordShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cookiesMessage, setCookiesMessage] = useState(true)
    // console.log(localForage());
    const [logo, setLogo] = useState(null)
    const [classImg, setClassImg] = useState("w-10")

    useEffect(() => {
        setErrMsg("");
    }, [username, password]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        await axios
            .post(url + "login", {
                // headers: headers,
                UserName: username,
                Password: password,
            })
            .then(function (response) {
                console.log(response.data); 
                setUser(response.data);
                setResourceCache(response.data.ResourcesCache !== undefined ? response.data.ResourcesCache : []);
                localForage(response.data.ResourcesCache);
                
                setResourcePagination(response.data.ResourcesPagination !== undefined ? response.data.ResourcesPagination : []);
                setVersionDb(response.data.Version !== undefined ? response.data.Version : "");
                localStorage.setItem('resourceCache', JSON.stringify(response.data.ResourcesCache))
                localStorage.setItem('resourcePagination', JSON.stringify(response.data.ResourcesPagination))
                localStorage.setItem("version", JSON.stringify(response.data.Version));
                localStorage.setItem("user", JSON.stringify(response.data));
                if (response.data.Workstations.length > 1) {
                    setWorkstationActive(null);
                } else {
                    setWorkstationActive(response.data.Workstations[0]);
                    localStorage.setItem(
                        "workstation",
                        JSON.stringify(response.data.Workstations[0])
                    );
                }
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response != undefined) {
                    setLoading(false);
                    if (error.response.status === 404) {
                        setErrMsg(
                            "Error de petición. Consulte con administración"
                        );
                    } else {
                        setErrMsg(error.response.data.Motivo);
                    }
                } else {
                    setLoading(false);
                    setErrMsg(
                        "Hubo un error crítico. Consulte con administración"
                    );
                }
            });
    };

    useEffect(() => {
      const getLogo = async () => {
        await axios.get(url+"login/logo", {
            responseType: 'arraybuffer'
        })
        .then(function(response){
            // Convertir los datos en un Blob
            const blob = new Blob([response.data], { type: "image/png" });

            // Crear una URL de objeto desde el Blob
            const imageUrl = URL.createObjectURL(blob);
            localStorage.setItem('logo', imageUrl);
            setLogo(imageUrl);
            setClassImg("w-full");
        }).catch(function(error){
            console.log(error);
            setLogo(logopw);
        })
      }
    
      getLogo();

      return () => {
        if (logo) {
            URL.revokeObjectURL(logo);
        }
      }
    }, []);
    
    
    return (
        <section>
            {loading && (
                <TripleMaze centered={true} width={"150px"} height={"150px"} />
            )}
            <div
                className={`min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 ${
                    loading ? "opacity-40" : "opacity-100"
                }`}
            >
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className={`mx-auto ${classImg}`}
                        src={logo}
                        alt="Logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Inicia sesión
                    </h2>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    {errMsg && (
                        <p className={"block text-red-400 bg-red-50 p-3"}>
                            {errMsg}
                        </p>
                    )}
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label
                                    htmlFor="username"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Username
                                </label>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input
                                        id="username"
                                        type="text"
                                        ref={userRef}
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        value={username}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm rounded-md"
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Password
                                </label>

                                <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                        type={
                                            passwordShow ? "text" : "password"
                                        }
                                        id="password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm rounded-md"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        value={password}
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        {passwordShow ? (
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setPasswordShow(
                                                        !passwordShow
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 text-gray-700"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setPasswordShow(
                                                        !passwordShow
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 text-gray-700"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                    />
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <a
                                        href="/"
                                        className="font-medium text-blue-600 hover:text-blue-500"
                                    >
                                        ¿Olvidó su contraseña?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Entrar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bottom-0 fixed w-full left-0 h-auto bg-gray-200 ">
                <Transition
                    show={cookiesMessage}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex justify-center py-4">
                        <button
                            onClick={() =>
                                setCookiesMessage(
                                    (cookiesMessage) => !cookiesMessage
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-10 w-10 text-gray-500 hover:text-gray-800"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <p className="font-mono text-sm text-justify">
                            Este sitio usa cookies para tener una experiencia{" "}
                            <br />
                            mejorada. Al usarlos, usted acepta nuestros{" "}
                            <a
                                className="underline text-blue-600 hover:text-blue-800"
                                href="https://policies.google.com/technologies/cookies?hl=es"
                            >
                                usos de cookies
                            </a>
                        </p>
                    </div>
                </Transition>
            </div>
        </section>
    );
};
