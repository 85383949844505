export const filterReducer = ( initialState = [], action = {}) => {

    switch (action.type) {
        case "addOrUpdateFilter":
            let foundFlag = false;

            let stateAdd = initialState.map((item, index) => {
                if (item.filterName === action.payload.filterName) {
                    foundFlag = true;
                    return action.payload;
                } else {
                    return item;
                }
            });

            if (!foundFlag) {
                stateAdd = [...initialState, action.payload];
            }   

            return stateAdd;

        case "deleteFilter":
            const stateDelete = initialState.filter(
                (item) => item.filterName != action.payload.filterName
            );

            return stateDelete;

        case "resetAllFilters":
            return [];

        case "clearFilter":
            const filters = initialState.filter(
                (item) => item.filterName != action.payload.filterName
                );
            return filters;

        case "softDeleteFilter":
            // console.log(initialState);
            const filtersAplied = action.payload.map((item) => {
                console.log(item);
            })
            // console.log(filtersAplied); 
            return filtersAplied;

            case 'validateFilters':
                const filtrarArray = initialState.map((item) => ({
                    ...item,
                    ...(item.aplicado === null && item.aplicado === false && { aplicado: item.aplicado })
                })).filter(item => item.aplicado === true || item.inicial !== undefined);
                // console.log(filtrarArray);
                return filtrarArray;
        default:
            return initialState;
    }
        

}