import { useContext } from "react";
import { useState } from "react";
import ModalContext from "../../../../context/ModalContext";
import { Alert } from "../../../Alerts";

export const FourthComponent = ({ nextStep, prevStep }) => {
    const { data, setData } = useContext(ModalContext);

    const [textNotasPublicas, setTextNotasPublicas] = useState(
        data.Operacion.Notas
    );
    const [textNotasPrivadas, setTextNotasPrivadas] = useState(
        data.NotasPrivadas
    );

    const handleNotasPrivadas = ({ target }) => {
        const { name, value } = target;
        setTextNotasPrivadas(value);
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleNotasPublicas = ({ target }) => {
        const { name, value } = target;
        setTextNotasPublicas(value);
        setData({
            ...data,
            Operacion: {
                ...data.Operacion,
                [name]: value,
            },
        });
    };

    const notasPublicas = () => {
        return (
            <textarea
                rows={6}
                cols={12}
                name="Notas"
                onChange={handleNotasPublicas}
                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                value={textNotasPublicas}
            />
        );
    };
    const notasPrivadas = () => {
        return (
            <textarea
                rows={6}
                cols={12}
                name="NotasPrivadas"
                id="NotasPrivadas"
                onChange={handleNotasPrivadas}
                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                value={textNotasPrivadas}
            />
        );
    };

    const [currentTab, setCurrentTab] = useState("1");

    const tabs = [
        { id: 1, tabTitle: "tab 1", title: "Notas", content: notasPublicas },
        { id: 2, tabTitle: "tab 2", title: "Notas privadas", content: notasPrivadas,},
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    };

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-between">
                    <button
                        className={`bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={prevStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.85Z"
                            ></path>
                        </svg>
                    </button>
                    <button
                        className={`ml-15 bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={nextStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h11.175q.4 0 .763.15t.637.425l2.85 2.85q.275.275.425.638t.15.762V12.4L12.4 21H5Zm7-3q1.25 0 2.125-.875T15 15q0-1.25-.875-2.125T12 12q-1.25 0-2.125.875T9 15q0 1.25.875 2.125T12 18Zm-5-8h7q.425 0 .713-.288T15 9V7q0-.425-.288-.713T14 6H7q-.425 0-.713.288T6 7v2q0 .425.288.713T7 10Zm14.75 8.025L17.075 22.7q-.15.15-.338.225T16.35 23h-.85q-.2 0-.35-.15T15 22.5v-.85q0-.2.075-.387t.225-.338L20 16.25l1.75 1.775Zm.65-.675l-1.775-1.75l.85-.85q.15-.15.363-.15t.362.15l1.05 1.05q.15.15.15.35t-.15.35l-.85.85Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <Alert />
            <div className="px-4 md:h-60 h-96 sm:px-6 md:mb-0 mb-5 lg:px-8 border border-transparent">
                <div className="mt-2 flow-root">
                    <div className="-mx-4 sm:-mx-6 lg:-mx-8">
                        <div className="overflow-auto md:h-52 h-96">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex" aria-label="Tabs">
                                    {tabs.map((tab, i) => (
                                        <button
                                            key={i}
                                            id={tab.id}
                                            className={`w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                                currentTab === `${tab.id}`
                                                    ? "border-indigo-500 text-indigo-600"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                            }`}
                                            onClick={handleTabClick}
                                        >
                                            {tab.title}
                                        </button>
                                    ))}
                                </nav>
                            </div>

                            <div className="md:content mt-5">
                                {tabs.map((tab, i) => (
                                    <div key={i}>
                                        {currentTab === `${tab.id}` && (
                                            <div>
                                                <p>{tab.content()}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};