import { useState, useContext, useEffect } from "react";

import { TripleMaze } from "react-spinner-animated";
import ModalContext from "../../../context/ModalContext";

import { Table } from "../../TableComponent";

export const SelectMonedaComponent = ({
    monedas,
    columns,
    nextStep,
    sortConfig,
    setSortConfig,
}) => {
    const [alerta, setAlerta] = useState("");
    const [loading, setLoading] = useState(false);

    const { select } = useContext(ModalContext);

    useEffect(() => {
        if (select) {
            setAlerta("");
        }
    }, [select]);

    const handleValidation = () => {
        var validation = false;

        if (select) {
            validation = true;
        }

        if (validation) {
            nextStep();
        } else {
            setAlerta("Debe seleccionar una moneda");
        }
    };

    return (
        <>
            <div>
                {alerta && (
                    <div className="rounded-md bg-yellow-100 p-4 absolute top-3 md:top-0 md:right-0 z-20">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-yellow-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                    />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-yellow-800">
                                    {alerta}
                                </p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        onClick={() => setAlerta("")}
                                        className="inline-flex rounded-md bg-yellow-100 p-1.5 text-yellow-600 hover:bg-yellow-200 focus:outline-none"
                                    >
                                        <span className="sr-only">Close</span>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex items-end justify-end mb-2 mt-3">
                    <button
                        className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={handleValidation}
                    >
                        Siguiente
                    </button>
                </div>

                {loading ? (
                    <TripleMaze
                        centered={true}
                        width={"350px"}
                        height={"350px"}
                        text={"Cargando..."}
                    />
                ) : (
                    <div className="h-96 overflow-y-auto">
                        <Table
                            data={monedas}
                            column={columns}
                            view={"single"}
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            // busqueda={busqueda}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
