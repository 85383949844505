import { useContext, useEffect } from "react";
import Moment from "moment";
import Selector from "../../../Selector/Selector";
import { SelectorGenerico, SelectorMenuTree } from "../../../Selector";
import ModalContext from "../../../../context/ModalContext";
import UserContext from "../../../../context/UserContext";
import { useActions } from "../../../../permissions/useActions";
import AlertContext from "../../../../context/AlertContext";

export const FirstComponent = ({ ruta, nextStep, tipoOrden }) => {
    const { data, setData } = useContext(ModalContext);
    const { workstationActive } = useContext(UserContext);
    const { isAllowed } = useActions();
    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const handleChange = ({ target }) => {
        const { name, value, type } = target;
        if (type == "date") {
            setData({
                ...data,
                [name]: Moment(value).format("YYYY-MM-DDTHH:mm:ss"),
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (data.DepositoEnvia === "" && tipoOrden === 0) {
            setData({
                ...data,
                DepositoEnvia: workstationActive.Deposito.Nombre,
                DepositoEnviaKey: workstationActive.Deposito.DepositoKey,
            });
        }
    }, []);

    const handleValidation = () => {
        if (data.SectorDestino === null && tipoOrden === 1) {
            setStatusCode(406);
            setResponseText("Debe seleccionar un Sector de Destino para continuar.");
            setTimeout(() => {
                setResponseText("");
            }, 3000);
        }
        else{
            nextStep();
        }
    };

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-end w-full">
                    <button
                        className={
                            ` 
                            bg-indigo-600
                            text-white
                            hover:bg-indigo-900
                            px-2
                            py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer
                            `}
                        onClick={handleValidation}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"></path></svg>
                    </button>
                </div>
            </div>
            <div className="md:grid md:grid-cols-2 max-h-60 h-60 md:ml-32">
                <div className="md:h-48 pt-4 space-y-4">
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="first-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Centro costo:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorMenuTree
                                        ruta={"centrocosto"}
                                        title={"Seleccione centro costo"}
                                        id={
                                            data.CentroCosto !== null &&
                                            data.CentroCosto &&
                                            data.CentroCosto.CentroCostoKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.CentroCosto === null
                                        ? "Sin seleccion"
                                        : data.CentroCosto &&
                                          JSON.stringify(
                                              data.CentroCosto.Nombre
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_cost_center/"}
                            />
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs normal-case font-semibold text-gray-900"
                        >
                            Depósito origen:
                        </label>
                        <div className="ml-4">
                            <p className="text-sm font-medium text-gray-700">
                                {workstationActive.Deposito.Nombre}
                            </p>
                        </div>
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Depósito destino:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"deposito"}
                                        view={"single"}
                                        title={"Seleccione depósito de destino"}
                                        id={
                                            data.DepositoRecibeKey !==
                                                null &&
                                            data.DepositoRecibeKey &&
                                            data.DepositoRecibeKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.DepositoRecibe === ""
                                        ? "Sin seleccion"
                                        : data.DepositoRecibe &&
                                            JSON.stringify(
                                                data.DepositoRecibe
                                            )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_destiny_deposit/"}
                            />
                        </div>
                    </div>
                </div>
                <div className="md:h-48 pt-4 space-y-4">
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="FechaPedido"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha:
                        </label>
                        <input
                            type="date"
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                            name="FechaPedido"
                            value={Moment(data.FechaPedido).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="FechaVencimiento"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Fecha vto.:
                        </label>
                        <input
                            type="date"
                            name="FechaVencimiento"
                            value={Moment(data.FechaVencimiento).format(
                                "yyyy-MM-DD"
                            )}
                            onChange={handleChange}
                            className="ml-4 h-6 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                        />
                    </div>
                    <div className="w-full inline-flex items-center">
                        <label
                            htmlFor="last-name"
                            className="block text-xs  font-semibold text-gray-900"
                        >
                            Empleado designado:
                        </label>
                        <div className="ml-4">
                            <Selector
                                content={
                                    <SelectorGenerico
                                        ruta={"vendedor"}
                                        view={"single"}
                                        title={"Seleccione empleado designado"}
                                        id={
                                            data.Empleado !== null &&
                                            data.Empleado &&
                                            data.Empleado.VendedorKey
                                        }
                                    />
                                }
                                textButton={`${
                                    data.Empleado === null
                                        ? "Sin seleccion"
                                        : data.Empleado &&
                                          JSON.stringify(
                                              data.Empleado.RazonSocial
                                          )
                                }`}
                                ruta={ruta}
                                action={"/to_assign_applicant/"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};