import { Route, Routes, useNavigate } from "react-router-dom";
import React, { Fragment, useState, useContext, useEffect } from "react";
import axios from "axios";
import UserContext from "../../context/UserContext";
import AlertContext from "../../context/AlertContext";
import WaitMessageContext from "../../context/WaitMessageContext";
import { useLogout } from "../../auth/pages";

//components
import Item from "../LinksItem";
import { Links } from "../../data/Links";
import {parseRoute} from "../../data/constants";

//pages
import { WelcomePage } from "../Home/WelcomePage";
import { SelectorGenerico } from "../Selector";
import * as Operacion from "../Operaciones/";
import { Page404 } from "../../auth";

//styles/icons
import "./index.css";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import { HomeIcon, XIcon } from "@heroicons/react/outline";
import { WorkstationSelection } from "./WorkstationSelection";
import WaitMessage from "./WaitMessage";

export const SidebarNavbar = () => {
    const {
        user,
        workstationActive,
        url,
        setVersionDb,
        versionDb,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const { logout } = useLogout();
    const ruta = window.location.pathname;
    const [open, setOpen] = useState(false);
    const [sidebarMinOpen, setSidebarMinOpen] = useState(false);
    const [statusCode, setStatusCode] = useState(null);
    const [responseText, setResponseText] = useState("");
    const [wait, setWait] = useState(false);
    const [waitMessage, setWaitMessage] = useState("");

    const changeWorkstation = () => {
        navigate("/selectWorkstation", {
            replace: true,
        });
    };

    const getVersionDb = async() => {
        await axios.request({
            url: url + "update/versiondb",
            method: "POST",
            data: {}
        }).then(function(res){
            console.log(res);
            setVersionDb(res.data.Version);
            localStorage.setItem("version", JSON.stringify(res.data.Version));
        }).catch(function(err){
            console.log(err);
        })
    }

    useEffect(() => {
        if (localStorage.getItem("version") === null ||
            localStorage.getItem("version") === "") {
            getVersionDb();
        } 
    }, [])

    useEffect(() => {
        if(open){
            setOpen(false);
        }
    }, [ruta])
    

    return (
        <>
            <div className="h-screen">
                <WaitMessageContext.Provider
                    value={{ setWait, setWaitMessage }}
                >
                    <WaitMessage state={wait} message={waitMessage} />
                    <AlertContext.Provider
                        value={{
                            statusCode,
                            setStatusCode,
                            responseText,
                            setResponseText,
                        }}
                    >
                        <Disclosure
                            as="nav"
                            style={{ backgroundColor: "#282828" }}
                            className="absolute shadow w-full z-10"
                        >
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 justify-between">
                                    <div className="flex space-x-3">
                                        <button
                                            className="sm:block hidden"
                                            onClick={() =>
                                                setSidebarMinOpen(
                                                    !sidebarMinOpen
                                                )
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6 text-white"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        </button>
                                        <div className="flex flex-shrink-0 items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="iconify iconify--nimbus h-9 w-9 text-white"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M11 8a2.64 2.64 0 0 0-.53-1.59l1.33-1.32a2.22 2.22 0 0 0 .82.17a1.94 1.94 0 0 0 2-1.88a2 2 0 0 0-4 0a1.76 1.76 0 0 0 .24.88L9.53 5.59A3.14 3.14 0 0 0 8 5.19a3 3 0 0 0-2.93 2.19h-.69A2 2 0 0 0 2.5 6.13A1.94 1.94 0 0 0 .5 8a1.94 1.94 0 0 0 2 1.88a2 2 0 0 0 1.88-1.25h.69A3 3 0 0 0 8 10.82A2.91 2.91 0 0 0 11 8zm1.62-5.24a.69.69 0 0 1 .75.62a.76.76 0 0 1-1.5 0a.7.7 0 0 1 .75-.62zM2.5 8.63A.7.7 0 0 1 1.75 8a.7.7 0 0 1 .75-.62a.7.7 0 0 1 .75.62a.7.7 0 0 1-.75.63zm5.5.94A1.67 1.67 0 0 1 6.25 8A1.66 1.66 0 0 1 8 6.44A1.67 1.67 0 0 1 9.75 8A1.68 1.68 0 0 1 8 9.57z"
                                                ></path>
                                                <path
                                                    fill="currentColor"
                                                    d="M2.5 4.38a2 2 0 0 0 .82-.17L5.08 6A3.73 3.73 0 0 1 6 5.13L4.26 3.38a1.76 1.76 0 0 0 .24-.88a1.94 1.94 0 0 0-2-1.87a1.94 1.94 0 0 0-2 1.87a1.94 1.94 0 0 0 2 1.88zm0-2.5a.7.7 0 0 1 .75.62a.7.7 0 0 1-.75.63a.7.7 0 0 1-.75-.63a.7.7 0 0 1 .75-.62zm11 9.75a2 2 0 0 0-.82.17L10.92 10a3.73 3.73 0 0 1-.93.84l1.74 1.74a1.75 1.75 0 0 0-.23.88a2 2 0 0 0 4 0a1.94 1.94 0 0 0-2-1.83zm0 2.5a.7.7 0 0 1-.75-.63a.76.76 0 0 1 1.5 0a.7.7 0 0 1-.75.63z"
                                                ></path>
                                            </svg>
                                            <span className="font-semibold text-sm tracking-tight text-white ml-2 uppercase">
                                                {window.location.hostname.split(".")[0]}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                        {/* Perfil dropdown */}
                                        <Menu
                                            as="div"
                                            className="relative ml-3"
                                        >
                                            <div className="inline-flex items-center">
                                                <div className="flex-col">
                                                    <p className="font-bold mr-4 text-white mt-2">
                                                        {user.Descripcion}
                                                    </p>
                                                    <p className="text-xs text-white flex-col">Workstation actual: {workstationActive.Nombre}</p>
                                                    
                                                </div>
                                                <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">
                                                        Open user menu
                                                    </span>
                                                    <img
                                                        className="h-10 w-10 rounded-full"
                                                        src={
                                                            localStorage.getItem(
                                                                "logo"
                                                            ) === null
                                                                ? "https://api.iconify.design/zondicons:user-solid-circle.svg?color=%23888888"
                                                                : localStorage.getItem(
                                                                      "logo"
                                                                  )
                                                        }
                                                        alt=""
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="w-full p-3 bg-gray-200 space-y-1">
                                                        <p className="text-xs font-bold text-gray-800">Usuario: {user.Nombre}</p>
                                                        <p className="text-xs text-gray-800 flex-col">Déposito: {workstationActive.Deposito.Nombre}</p>
                                                    </div>
                                                    <Menu.Item>
                                                        <button
                                                            className="inline-flex items-center w-full text-left py-2 px-4 text-sm text-gray-700 hover:bg-gray-200"
                                                            onClick={
                                                                changeWorkstation
                                                            }
                                                        >
                                                            <svg
                                                                className="h-5 w-5 text-gray-700 mr-4"
                                                                viewBox="0 0 512 512"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M258.148 20.822c-1.112.008-2.226.026-3.343.055c-39.32 1.041-81.507 15.972-123.785 50.404l-6.028 4.91l-5.732-5.25c-12.644-11.578-20.276-27.633-25.653-43.716c-8.974 36.98-14.631 81.385-9.232 114.523c18.065.908 45.409-2.177 73.7-7.818c17.858-3.561 36.048-8.126 53.064-13.072c-13.419-2.911-25.896-6.882-38.143-12.082l-16.088-6.832l14.906-9.127c46.367-28.393 80.964-40.686 120.235-35.553c33.105 4.327 69.357 20.867 119.066 47.271c-25.373-36.314-62.243-64.737-104.728-76.994c-15.402-4.443-31.553-6.828-48.239-6.719zM346 116c-46.667 0-46.666 0-46.666 46.666V349.4c0 9.596.007 17.19.414 23.242a664.804 664.804 0 0 1 50.656-12.223c24.649-4.915 48.367-8.224 67.916-8.41c6.517-.062 12.571.224 18.041.912l6.31.793l1.358 6.213c2.464 11.265 3.673 23.447 3.914 36.059c38.032-.19 38.057-3.06 38.057-46.65V162.665C486 116 486 116 439.334 116a226.98 226.98 0 0 1 3.978 7.64l12.624 25.536l-25.004-13.648c-13.085-7.143-25.164-13.632-36.452-19.528zm-281.943.016c-38.032.19-38.057 3.06-38.057 46.65V349.4C26 396 26 396 72.666 396a226.98 226.98 0 0 1-3.978-7.64l-12.624-25.536l25.004 13.649c13.085 7.142 25.164 13.632 36.452 19.527H166c46.667 0 46.666 0 46.666-46.666V162.666c0-9.626-.006-17.24-.416-23.304a664.811 664.811 0 0 1-50.654 12.22c-32.865 6.554-64.077 10.25-85.957 7.498l-6.31-.793l-1.358-6.213c-2.464-11.265-3.673-23.446-3.914-36.058zm354.619 254.078c-17.543.25-40.826 3.206-64.75 7.977c-17.859 3.56-36.05 8.125-53.065 13.072c13.419 2.91 25.896 6.881 38.143 12.082l16.088 6.832l-14.906 9.127c-46.367 28.392-80.964 40.685-120.235 35.553c-33.105-4.327-69.357-20.868-119.066-47.272c25.373 36.315 62.243 64.738 104.728 76.994c52.573 15.166 113.872 6.343 175.367-43.74l6.028-4.91l5.732 5.25c12.644 11.579 20.276 27.633 25.653 43.717c8.974-36.981 14.631-81.386 9.232-114.524c-2.788-.14-5.748-.204-8.95-.158z"
                                                                ></path>
                                                            </svg>
                                                            Cambiar workstation
                                                        </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button
                                                            className="inline-flex items-center w-full text-left py-2 px-4 text-sm text-gray-700 hover:bg-gray-200"
                                                            onClick={logout}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-5 w-5 text-gray-700 mr-4"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                                />
                                                            </svg>
                                                            Cerrar sesión
                                                        </button>
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                    <div className="-mr-2 flex items-center sm:hidden">
                                        {/* Boton de menu movil */}
                                        <Disclosure.Button
                                            onClick={() => setOpen(!open)}
                                            className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none"
                                        >
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>
                            <Transition.Root show={open} as={Fragment}>
                                <Dialog
                                    as="div"
                                    className="relative z-10"
                                    onClose={setOpen}
                                >
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 overflow-hidden">
                                        <div className="absolute inset-0 overflow-hidden">
                                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                                    enterFrom="translate-x-full"
                                                    enterTo="translate-x-0"
                                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                                    leaveFrom="translate-x-0"
                                                    leaveTo="translate-x-full"
                                                >
                                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                                            <div className="px-4 sm:px-6">
                                                                <div className="flex items-start justify-between">
                                                                    <Dialog.Title>
                                                                        <Disclosure>
                                                                            {({
                                                                                open,
                                                                            }) => (
                                                                                <>
                                                                                    <Disclosure.Button>
                                                                                        <div className="inline-flex items-center">
                                                                                            <div className="inline-flex items-center space-x-3">
                                                                                                <img
                                                                                                    className="h-10 w-10 rounded-full"
                                                                                                    src={
                                                                                                        localStorage.getItem(
                                                                                                            "logo"
                                                                                                        ) ===
                                                                                                        null
                                                                                                            ? "https://api.iconify.design/zondicons:user-solid-circle.svg?color=%23888888"
                                                                                                            : localStorage.getItem(
                                                                                                                  "logo"
                                                                                                              )
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                                <p>
                                                                                                    {
                                                                                                        user.Descripcion
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                            <svg
                                                                                                className={`ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-200 ease-in-out md:group-hover:text-gray-400 ${
                                                                                                    open
                                                                                                        ? " rotate-90 transform transition-all"
                                                                                                        : "md:text-gray-300 text-gray-800"
                                                                                                }`}
                                                                                                viewBox="0 0 20 20"
                                                                                                aria-hidden="true"
                                                                                            >
                                                                                                <path
                                                                                                    d="M6 6L14 10L6 14V6Z"
                                                                                                    fill="currentColor"
                                                                                                />
                                                                                            </svg>
                                                                                        </div>
                                                                                    </Disclosure.Button>
                                                                                    <Transition
                                                                                        enter="transition duration-100 ease-out"
                                                                                        enterFrom="transform scale-95 opacity-0"
                                                                                        enterTo="transform scale-100 opacity-100"
                                                                                        leave="transition duration-75 ease-out"
                                                                                        leaveFrom="transform scale-100 opacity-100"
                                                                                        leaveTo="transform scale-95 opacity-0"
                                                                                    >
                                                                                        <Disclosure.Panel>
                                                                                            <div className="w-full p-3 space-y-1">
                                                                                                <p className="text-xs font-bold text-gray-800">Usuario: {user.Nombre}</p>
                                                                                                <p className="text-xs text-gray-800 flex-col">Déposito: {workstationActive.Deposito.Nombre}</p>
                                                                                            </div>
                                                                                            <Disclosure.Button
                                                                                                className={`inline-flex items-center w-full text-left p-2 text-sm text-gray-700 hover:bg-gray-200`}
                                                                                                onClick={
                                                                                                    changeWorkstation
                                                                                                }
                                                                                            >
                                                                                                <svg
                                                                                                    className="h-5 w-5 text-gray-700 mr-4"
                                                                                                    viewBox="0 0 512 512"
                                                                                                >
                                                                                                    <path
                                                                                                        fill="currentColor"
                                                                                                        d="M258.148 20.822c-1.112.008-2.226.026-3.343.055c-39.32 1.041-81.507 15.972-123.785 50.404l-6.028 4.91l-5.732-5.25c-12.644-11.578-20.276-27.633-25.653-43.716c-8.974 36.98-14.631 81.385-9.232 114.523c18.065.908 45.409-2.177 73.7-7.818c17.858-3.561 36.048-8.126 53.064-13.072c-13.419-2.911-25.896-6.882-38.143-12.082l-16.088-6.832l14.906-9.127c46.367-28.393 80.964-40.686 120.235-35.553c33.105 4.327 69.357 20.867 119.066 47.271c-25.373-36.314-62.243-64.737-104.728-76.994c-15.402-4.443-31.553-6.828-48.239-6.719zM346 116c-46.667 0-46.666 0-46.666 46.666V349.4c0 9.596.007 17.19.414 23.242a664.804 664.804 0 0 1 50.656-12.223c24.649-4.915 48.367-8.224 67.916-8.41c6.517-.062 12.571.224 18.041.912l6.31.793l1.358 6.213c2.464 11.265 3.673 23.447 3.914 36.059c38.032-.19 38.057-3.06 38.057-46.65V162.665C486 116 486 116 439.334 116a226.98 226.98 0 0 1 3.978 7.64l12.624 25.536l-25.004-13.648c-13.085-7.143-25.164-13.632-36.452-19.528zm-281.943.016c-38.032.19-38.057 3.06-38.057 46.65V349.4C26 396 26 396 72.666 396a226.98 226.98 0 0 1-3.978-7.64l-12.624-25.536l25.004 13.649c13.085 7.142 25.164 13.632 36.452 19.527H166c46.667 0 46.666 0 46.666-46.666V162.666c0-9.626-.006-17.24-.416-23.304a664.811 664.811 0 0 1-50.654 12.22c-32.865 6.554-64.077 10.25-85.957 7.498l-6.31-.793l-1.358-6.213c-2.464-11.265-3.673-23.446-3.914-36.058zm354.619 254.078c-17.543.25-40.826 3.206-64.75 7.977c-17.859 3.56-36.05 8.125-53.065 13.072c13.419 2.91 25.896 6.881 38.143 12.082l16.088 6.832l-14.906 9.127c-46.367 28.392-80.964 40.685-120.235 35.553c-33.105-4.327-69.357-20.868-119.066-47.272c25.373 36.315 62.243 64.738 104.728 76.994c52.573 15.166 113.872 6.343 175.367-43.74l6.028-4.91l5.732 5.25c12.644 11.579 20.276 27.633 25.653 43.717c8.974-36.981 14.631-81.386 9.232-114.524c-2.788-.14-5.748-.204-8.95-.158z"
                                                                                                    ></path>
                                                                                                </svg>
                                                                                                Cambiar
                                                                                                workstation
                                                                                            </Disclosure.Button>
                                                                                            <Disclosure.Button
                                                                                                className={`inline-flex items-center w-full text-left p-2 text-sm text-gray-700 hover:bg-gray-200`}
                                                                                                onClick={
                                                                                                    logout
                                                                                                }
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    className="h-5 w-5 text-gray-700 mr-4"
                                                                                                    fill="none"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="2"
                                                                                                >
                                                                                                    <path
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                                                                    />
                                                                                                </svg>
                                                                                                Cerrar
                                                                                                sesión
                                                                                            </Disclosure.Button>
                                                                                        </Disclosure.Panel>
                                                                                    </Transition>
                                                                                </>
                                                                            )}
                                                                        </Disclosure>
                                                                    </Dialog.Title>
                                                                    <div className="ml-3 flex h-7 items-center">
                                                                        <button
                                                                            className="relative rounded-md bg-white text-gray-400"
                                                                            onClick={() =>
                                                                                setOpen(
                                                                                    !open
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="absolute -inset-2.5" />
                                                                            <span className="sr-only">
                                                                                Close
                                                                                panel
                                                                            </span>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M6 18L18 6M6 6l12 12"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`bg-gray-600 w-full flex justify-center mt-2`}
                                                            >
                                                                <button
                                                                    onClick={
                                                                        getVersionDb
                                                                    }
                                                                    className="text-fuchsia-200 text-xs hover:underline "
                                                                >
                                                                    {"Version: " +
                                                                        versionDb}
                                                                </button>
                                                            </div>
                                                            <div className="relative mt-2 flex-1 px-4 sm:px-6">
                                                                <Item
                                                                    to={"/"}
                                                                    text={
                                                                        "Inicio"
                                                                    }
                                                                    svg={
                                                                        <HomeIcon className="font-bold mr-4 flex-shrink-0 h-6 w-6" />
                                                                    }
                                                                    openSidebar={
                                                                        sidebarMinOpen
                                                                    }
                                                                />
                                                                {Links &&
                                                                    Links.map(
                                                                        ({
                                                                            to,
                                                                            text,
                                                                            svg,
                                                                            children,
                                                                            action,
                                                                        }) => (
                                                                            <Item
                                                                                key={
                                                                                    text
                                                                                }
                                                                                to={
                                                                                    to
                                                                                }
                                                                                text={
                                                                                    text
                                                                                }
                                                                                svg={
                                                                                    svg
                                                                                }
                                                                                openSidebar={
                                                                                    sidebarMinOpen
                                                                                }
                                                                                children={
                                                                                    children
                                                                                }
                                                                                action={
                                                                                    action
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </Dialog.Panel>
                                                </Transition.Child>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                        </Disclosure>
                        <div
                            className="h-screen flex"
                            style={{ backgroundColor: "#282828" }}
                        >
                            <div
                                className={`${
                                    sidebarMinOpen
                                        ? "w-16 transition-all"
                                        : "transition-all w-1/4"
                                }  sm:block hidden mt-16`}
                            >
                                {/* vista desktop & tablet */}
                                <div
                                    className={`bg-gray-600  justify-center md:flex hidden w-full transition-all`}
                                >
                                    <button
                                        onClick={getVersionDb}
                                        className="text-fuchsia-200 text-xs hover:underline "
                                    >
                                        {sidebarMinOpen
                                            ? "V.O"
                                            : "Version: " + versionDb}
                                    </button>
                                </div>
                                <nav
                                    className="pb-5 md:block hidden"
                                    aria-label="Sidebar"
                                >
                                    <Item
                                        to={"/"}
                                        text={"Inicio"}
                                        svg={
                                            <HomeIcon className="font-bold mr-4 flex-shrink-0 h-6 w-6" />
                                        }
                                        action={"MAIN_MENU_INICIO"}
                                        openSidebar={sidebarMinOpen}
                                    />
                                    {Links &&
                                        Links.map(
                                            ({
                                                to,
                                                text,
                                                svg,
                                                children,
                                                action,
                                                noKey,
                                            }) => (
                                                <Item
                                                    key={text}
                                                    to={to}
                                                    text={text}
                                                    svg={svg}
                                                    openSidebar={sidebarMinOpen}
                                                    children={children}
                                                    action={action}
                                                    noKey={noKey}
                                                />
                                            )
                                        )}
                                </nav>

                                {/* fin vista desktop & tablet */}
                            </div>
                            <div className="w-full mt-14 overflow-hidden bg-white">
                                <Routes>
                                    <Route path="/" element={<WelcomePage />} />
                                    <Route
                                        path="/selectWorkstation"
                                        element={<WorkstationSelection />}
                                    />
                                    {Links.map(
                                        (ruta) =>
                                            ruta.children &&
                                            ruta.children.map((children) => (
                                                <Route
                                                    key={children.ruta}
                                                    path={`${children.to}`}
                                                    element={
                                                        ruta.operacion ? (
                                                            React.createElement(
                                                                parseRoute(
                                                                    ruta.to
                                                                )
                                                            )
                                                        ) : (
                                                            <SelectorGenerico
                                                                ruta={
                                                                    children.ruta
                                                                }
                                                                title={
                                                                    children.text
                                                                }
                                                            />
                                                        )
                                                        // <SelectorGenerico
                                                        //     ruta={children.ruta}
                                                        //     title={children.text}
                                                        // />
                                                    }
                                                />
                                            ))
                                    )}

                                    <Route
                                        path="/pc"
                                        element={
                                            <Operacion.PresupuestoCliente />
                                        }
                                    />
                                    <Route
                                        path="/opid"
                                        element={
                                            <Operacion.OrdenPedidoInternaDeposito />
                                        }
                                    />
                                    <Route
                                        path="/opis"
                                        element={
                                            <Operacion.OrdenPedidoInternaSectorizada />
                                        }
                                    />
                                    <Route
                                        path="/oti"
                                        element={
                                            <Operacion.OrdenTrabajoInterna />
                                        }
                                    />
                                    <Route
                                        path="/occ"
                                        element={
                                            <Operacion.OrdenCompraCliente />
                                        }
                                    />
                                    <Route
                                        path="/ocp"
                                        element={
                                            <Operacion.OrdenCompraProveedor />
                                        }
                                    />
                                    <Route
                                        path="/opo"
                                        element={<Operacion.ParteOperativo />}
                                    />
                                    <Route
                                        path="/venta"
                                        element={<Operacion.Venta />}
                                    />
                                    <Route path="/*" element={<Page404 />} />
                                </Routes>
                            </div>
                        </div>
                    </AlertContext.Provider>
                </WaitMessageContext.Provider>
            </div>
        </>
    );
};
