export const Links = [
    {
        text: "Listados",
        operacion: false,
        noKey: true,
        action: "MAIN_MENU_LISTADO",
        svg: (
            <svg
                className="font-bold mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
            </svg>
        ),
        children: [
            {
                to: "/articulo",
                ruta: "articulo",
                text: "Articulos",
                action: "MAIN_MENU_ARTICULO",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="font-bold mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                        />
                    </svg>
                ),
            },
            {
                to: "/banco",
                ruta: "banco",
                text: "Banco",
                action: "MAIN_MENU_LISTADO_BANCO",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="font-bold mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                        />
                    </svg>
                ),
            },
            {
                to: "/centrocosto",
                ruta: "centrocosto",
                text: "Centro costo",
                action: "MAIN_MENU_LISTADO_CENTROCOSTO",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M10.5 7a3 3 0 1 0 0 6a3 3 0 0 0 0-6ZM9 10a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0ZM2 6.25A2.25 2.25 0 0 1 4.25 4h12.5A2.25 2.25 0 0 1 19 6.25V11h-1.5V8.5h-.75a2.25 2.25 0 0 1-2.25-2.25V5.5h-8v.75A2.25 2.25 0 0 1 4.25 8.5H3.5v3h.75a2.25 2.25 0 0 1 2.25 2.25v.75H14V16H4.25A2.25 2.25 0 0 1 2 13.75v-7.5Zm2.25-.75a.75.75 0 0 0-.75.75V7h.75A.75.75 0 0 0 5 6.25V5.5h-.75ZM17.5 7v-.75a.75.75 0 0 0-.75-.75H16v.75c0 .414.336.75.75.75h.75Zm-14 6.75c0 .414.336.75.75.75H5v-.75a.75.75 0 0 0-.75-.75H3.5v.75Zm.901 3.75H14V19H7a3 3 0 0 1-2.599-1.5ZM22 11V9a3 3 0 0 0-1.5-2.599V11H22Zm-5.5 1a1.5 1.5 0 0 0-1.5 1.5v8a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-8a1.5 1.5 0 0 0-1.5-1.5h-5Zm.5 4.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1Zm3 0h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1Zm-3 2h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1Zm3 0h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1Zm-3 2h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1Zm3 0h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1ZM16.5 14a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1Z"
                        ></path>
                    </svg>
                ),
            },

            {
                to: "/cliente",
                ruta: "cliente",
                text: "Clientes",
                action: "MAIN_MENU_LISTADO_CLIENTE",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--heroicons-outline font-bold mr-4 flex-shrink-0 h-6 w-6"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                    >
                        <path
                            fill="none"
                            stroke="currentColor"
                            d="M12 4.354a4 4 0 1 1 0 5.292M15 21H3v-1a6 6 0 0 1 12 0v1Zm0 0h6v-1a6 6 0 0 0-9-5.197M13 7a4 4 0 1 1-8 0a4 4 0 0 1 8 0Z"
                        ></path>
                    </svg>
                ),
            },
            {
                to: "/condicionpago",
                ruta: "condicionpago",
                text: "Condicion Pago",
                action: "MAIN_MENU_LISTADO_CONDICIONPAGO",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                        />
                    </svg>
                ),
            },
            // {
            //     to: "/contratooperacion",
            //     ruta: "contratooperacion",
            //     text: "Contrato",
            //     action: "",
            //     svg: (
            //         <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             className="mr-4 flex-shrink-0 h-6 w-6"
            //             fill="none"
            //             viewBox="0 0 24 24"
            //             stroke="currentColor"
            //             strokeWidth={2}
            //         >
            //             <path
            //                 strokeLinecap="round"
            //                 strokeLinejoin="round"
            //                 d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            //             />
            //         </svg>
            //     ),
            // },
            // {
            //     to: "/contratooperacion",
            //     ruta: "contratooperacion",
            //     text: "Contrato",
            //     action: "",
            //     svg: (
            //         <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             className="mr-4 flex-shrink-0 h-6 w-6"
            //             fill="none"
            //             viewBox="0 0 24 24"
            //             stroke="currentColor"
            //             strokeWidth={2}
            //         >
            //             <path
            //                 strokeLinecap="round"
            //                 strokeLinejoin="round"
            //                 d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            //             />
            //         </svg>
            //     ),
            // },
            {
                to: "/marca",
                ruta: "marca",
                text: "Marcas",
                action: "MAIN_MENU_LISTADO_MARCA",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--heroicons-outline font-bold mr-4 flex-shrink-0 h-6 w-6"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                    >
                        <path
                            fill="none"
                            stroke="currentColor"
                            d="M19 11H5m14 0a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2m14 0V9a2 2 0 0 0-2-2M5 11V9a2 2 0 0 1 2-2m0 0V5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2M7 7h10"
                        ></path>
                    </svg>
                ),
            },
            {
                to: "/moneda",
                ruta: "moneda",
                text: "Moneda",
                action: "MAIN_MENU_LISTADO_MONEDA",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                ),
            },
            {
                to: "/proveedor",
                ruta: "proveedor",
                text: "Proveedores",
                action: "MAIN_MENU_LISTADO_PROVEEDOR",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                        />
                    </svg>
                ),
            },
            {
                to: "/sector",
                ruta: "sector",
                text: "Sector",
                action: "MAIN_MENU_LISTADO_SECTOR",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                ),
            },
            {
                to: "/vendedor",
                ruta: "vendedor",
                text: "Vendedores",
                action: "MAIN_MENU_LISTADO_VENDEDOR",
                svg: (
                    <svg
                        className="iconify iconify--material-symbols mr-4 flex-shrink-0 h-6 w-6"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M2 16v-2.8q0-.85.438-1.563q.437-.712 1.162-1.087q1.55-.775 3.15-1.163Q8.35 9 10 9q1.125 0 2.225.175t2.2.55q-.425.35-.775.762q-.35.413-.625.888q-.75-.2-1.5-.288Q10.775 11 10 11q-1.4 0-2.775.337q-1.375.338-2.725 1.013q-.225.125-.362.35q-.138.225-.138.5v.8h8.025q-.025.5-.013 1q.013.5.063 1Zm2-2h8.025Zm6-6Q8.35 8 7.175 6.825Q6 5.65 6 4q0-1.65 1.175-2.825Q8.35 0 10 0q1.65 0 2.825 1.175Q14 2.35 14 4q0 1.65-1.175 2.825Q11.65 8 10 8Zm0-2q.825 0 1.413-.588Q12 4.825 12 4t-.587-1.413Q10.825 2 10 2q-.825 0-1.412.587Q8 3.175 8 4q0 .825.588 1.412Q9.175 6 10 6Zm0-2Zm10 0q0 1.65-1.175 2.825Q17.65 8 16 8q-.275 0-.7-.062q-.425-.063-.7-.138q.675-.8 1.037-1.775Q16 5.05 16 4q0-1.05-.363-2.025Q15.275 1 14.6.2q.35-.125.7-.163Q15.65 0 16 0q1.65 0 2.825 1.175Q20 2.35 20 4Zm-1 16q-2.075 0-3.537-1.462Q14 17.075 14 15q0-2.1 1.463-3.55Q16.925 10 19 10q2.1 0 3.55 1.45Q24 12.9 24 15q0 2.075-1.45 3.538Q21.1 20 19 20Zm-.7-2.75l3.525-3.55l-.7-.7l-2.825 2.825l-1.425-1.425l-.7.725Z"
                        ></path>
                    </svg>
                ),
            },
            {
                to: "/unidad",
                ruta: "unidad",
                text: "Unidad",
                action: "MAIN_MENU_LISTADO_UNIDAD",
                svg: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                    </svg>
                ),
            },
            {
                to: "/zona",
                ruta: "zona",
                text: "Zonas",
                action: "MAIN_MENU_LISTADO_ZONA",
                svg: (
                    <svg
                        className="mr-4 flex-shrink-0 h-6 w-6"
                        viewBox="0 0 24 24"
                    >
                        <g fill="none">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"></path>
                            <path
                                fill="currentColor"
                                d="M6.72 16.64a1 1 0 0 1 .56 1.92c-.5.146-.86.3-1.091.44c.238.143.614.303 1.136.452C8.48 19.782 10.133 20 12 20s3.52-.218 4.675-.548c.523-.149.898-.309 1.136-.452c-.23-.14-.59-.294-1.09-.44a1 1 0 0 1 .559-1.92c.668.195 1.28.445 1.75.766c.435.299.97.82.97 1.594c0 .783-.548 1.308-.99 1.607c-.478.322-1.103.573-1.786.768C15.846 21.77 14 22 12 22s-3.846-.23-5.224-.625c-.683-.195-1.308-.446-1.786-.768c-.442-.3-.99-.824-.99-1.607c0-.774.535-1.295.97-1.594c.47-.321 1.082-.571 1.75-.766ZM12 2a7.5 7.5 0 0 1 7.5 7.5c0 2.568-1.4 4.656-2.85 6.14a16.402 16.402 0 0 1-1.853 1.615c-.594.446-1.952 1.282-1.952 1.282a1.71 1.71 0 0 1-1.69 0a20.736 20.736 0 0 1-1.952-1.282A16.29 16.29 0 0 1 7.35 15.64C5.9 14.156 4.5 12.068 4.5 9.5A7.5 7.5 0 0 1 12 2Zm0 2a5.5 5.5 0 0 0-5.5 5.5c0 1.816.996 3.428 2.28 4.74c.966.988 2.03 1.74 2.767 2.202l.453.274l.453-.274c.736-.462 1.801-1.214 2.767-2.201c1.284-1.313 2.28-2.925 2.28-4.741A5.5 5.5 0 0 0 12 4Zm0 2.5a3 3 0 1 1 0 6a3 3 0 0 1 0-6Zm0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2Z"
                            ></path>
                        </g>
                    </svg>
                ),
            },
        ],
    },
    {
        to: "/occ",
        ruta: "ordencompracliente",
        text: "Orden de compra de cliente",
        action: "MAIN_MENU_OPERACION_ORDEN_COMPRA_CLIENTE",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="iconify iconify--mdi mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M17 9H8V7h9v2M7 7H5v2h2V7m0-3H5v2h2V4m3 0H8v2h2V4m3 13v2h1c.55 0 1 .45 1 1h7v2h-7c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1H2v-2h7c0-.55.45-1 1-1h1v-2H4c-1.11 0-2-.89-2-2V3a2 2 0 0 1 2-2h16c1.11 0 2 .89 2 2v12c0 1.11-.89 2-2 2h-7m7-2V3H4v12h16m-9-9h8V4h-8v2m-6 6h6v-2H5v2m8 2h6v-2h-6v2Z"
                ></path>
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/ocp",
        ruta: "ordencompraproveedor",
        text: "Orden de compra de proveedor",
        action: "MAIN_MENU_OPERACION_ORDEN_COMPRA_PROVEEDOR",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="iconify iconify--mdi mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M17 9H8V7h9v2M7 7H5v2h2V7m0-3H5v2h2V4m3 0H8v2h2V4m3 13v2h1c.55 0 1 .45 1 1h7v2h-7c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1H2v-2h7c0-.55.45-1 1-1h1v-2H4c-1.11 0-2-.89-2-2V3a2 2 0 0 1 2-2h16c1.11 0 2 .89 2 2v12c0 1.11-.89 2-2 2h-7m7-2V3H4v12h16m-9-9h8V4h-8v2m-6 6h6v-2H5v2m8 2h6v-2h-6v2Z"
                ></path>
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/opo",
        ruta: "opo",
        text: "Partes operativos",
        action: "MAIN_MENU_OPERACION_PARTE_OPERATIVO",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4 flex-shrink h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
                />
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/pc",
        ruta: "pc",
        text: "Presupuesto de cliente",
        action: "MAIN_MENU_OPERACION_PRESUPUESTO_CLIENTE",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                />
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/opis",
        ruta: "opis",
        text: "Orden de pedido sectorizada",
        action: "MAIN_MENU_OPERACION_ORDEN_PEDIDO_SECTORIZADA",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/opid",
        ruta: "opid",
        text: "Orden de pedido deposito",
        action: "MAIN_MENU_OPERACION_ORDEN_PEDIDO_DEPOSITO",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/ordentrabajointerna",
        ruta: "ordentrabajointerna",
        text: "Orden de trabajo interna",
        action: "MAIN_MENU_OPERACION_ORDEN_TRABAJO",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4 flex-shrink-0 h-6 w-6"
                viewBox="0 0 32 32"
            >
                <path
                    fill="currentColor"
                    d="M19 21h-6a3 3 0 0 0-3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0-3-3m-3-1a4 4 0 1 0-4-4a4 4 0 0 0 4 4m0-6a2 2 0 1 1-2 2a2 2 0 0 1 2-2"
                ></path>
                <path
                    fill="currentColor"
                    d="M25 5h-3V4a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v1H7a2 2 0 0 0-2 2v21a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2M12 4h8v4h-8Zm13 24H7V7h3v3h12V7h3Z"
                ></path>
            </svg>
        ),
        operacion: true,
    },
    {
        to: "/venta",
        ruta: "venta",
        text: "Ventas",
        action: "MAIN_MENU_OPERACION_VENTAS",
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-4 flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                />
            </svg>
        ),
        operacion: true,
    },
];
