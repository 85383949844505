import { useContext, useState, useEffect } from "react";
import UserContext from "../../../context/UserContext";
import axios from "axios";
import ModalContext from "../../../context/ModalContext";
import AlertContext from "../../../context/AlertContext";
import { useResourcesCache } from "../../../hooks/useResourcesCache";
import localforage from 'localforage';

export const useSelectorGenerico = (ruta, filter, id, extraArgument = '') => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [acciones, setAcciones] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const [paginationFinish, setPaginationFinish] = useState(false);
    const [abortController, setAbortController] = useState(null);

    const {
        user,
        workstationActive,
        headers,
        url,
        resourcePagination,
        resourceCache,
    } = useContext(UserContext);
    // const { db } = useLocalForage();
    const { setResponseText, setStatusCode } = useContext(AlertContext);
    const { select, setSelect } = useContext(ModalContext);
    const { findResourceCache, findResourceOffset } = useResourcesCache();
    const [allColumns, setAllColumns] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const filterResult = data.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );

    // console.log(findResourceCache(resourceCache, ruta));
    const valueOffset = findResourceOffset(resourcePagination, ruta);

    const getData = async (offset, resourceData = []) => {
        const controller = new AbortController();
        const offsetBody = valueOffset !== -1 ? (offset === undefined ? 0 : offset) : -1;
        setAbortController(controller);
        setLoading(true);
        setPaginationFinish(true);
        console.log(offsetBody);
        await axios
            .post(
                `${url}${ruta}/readall${
                    extraArgument !== "" ? extraArgument : ""
                }`,
                {
                    Workstation: workstationActive.WorkstationKey,
                    Usuario: user.UsuarioKey,
                    RequiereContexto: true,
                    Offset: offsetBody,
                },
                {
                    signal: controller.signal,
                }
                )
            .then(function (response) {
                const accumulatedData = resourceData.concat(response.data.Data);
                if (response.data.Data.length === valueOffset) {
                    getData(offsetBody !== -1 && offsetBody + 1, accumulatedData);
                } else {
                    setPaginationFinish(false);
                    if (findResourceCache(resourceCache, ruta)) {
                        let objResource = {
                            Data: accumulatedData,
                            WebForms: response.data.WebForms,
                            Acciones: response.data.Acciones,
                        };
                        localStorage.setItem(ruta, JSON.stringify(objResource));
                    }
                }
                setData((data) => [...data, ...response.data.Data]);
                setLoading(false);
                setAllColumns(response.data.WebForms);
                setAcciones(response.data.Acciones);
            })
            .catch(function (error) {
                console.log(error);
                if (error.name === "CanceledError") {
                    if (findResourceCache(resourceCache, ruta)) {
                        localStorage.removeItem(ruta);
                    }
                    setResponseText("La carga anterior fue cancelada.");
                    setTimeout(() => {
                        setResponseText("");
                    }, 2000);
                    setStatusCode(404);
                } else {
                    if (error.response != undefined) {
                        setLoading(false);
                        setData([]);
                        if (error.response.status === 404) {
                            setResponseText(
                                "Error de petición. Consulte con administración"
                            );
                        } else {
                            setResponseText(error.response.data.Motivo);
                        }
                        setStatusCode(error.response.status);
                    } else {
                        setLoading(false);
                        setData([]);
                        setResponseText(
                            "Hubo un error crítico. Consulte con administración"
                        );
                    }
                }
            });
    };

    // Cuando el controlador de la 'signal' cambia y a su vez tenia una 'signal' activa se cancela la petición
    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController]);

    // Cuando la ruta existe, se ejecuta la petición
    useEffect(() => {
        console.log(localforage);
        // console.log("localforage,", db.getItem(ruta).then(function(value){
        //     console.log(value);
        // }).catch(function(err){
        //     console.log(err);
        // }));
        if (findResourceCache(resourceCache, ruta) && localStorage.getItem(ruta)) {
            let data = JSON.parse(localStorage.getItem(ruta));
            setData(data.Data);
            setAcciones(data.Acciones);
            setAllColumns(data.WebForms);
        }else{
            getData();
        }

        return () => {
            if (abortController) {
                abortController.abort();
                setAbortController(null);
            }
            setData([]);
            setResponseText("");
            setBusqueda("");
        };
    }, [ruta]);

    useEffect(() => {
        if (id === false) {
            setSelect([]);
        }
        if (select === undefined || setSelect === undefined) {
            return;
        }
        if (select !== null) {
            if (id === parseInt(select)) {
                setSelect(id);
            } else if (select.length > 0) {
                setSelect(id);
            } else {
                setSelect(id);
            }
        }
    }, [id]);

    useEffect(() => {
        // console.log("cambios en el table format");
    }, [allColumns]);

    const applyChanges = (e) => {
        setAllColumns([...e]);
        // console.log(allColumns);
    };

    const saveChanges = async (dataIncoming) => {
        // console.log("guardando cambios...");

        const dataColumn = dataIncoming.map((item, index) => {
            var newData = {
                UsuarioKey: user.UsuarioKey,
                WebFormDefaultKey: item.WebFormDefaultKey,
                FieldWidth: item.FieldWidth,
                Visible: item.Visible,
                Orden: index + 1,
            };
            return newData;
        });

        // // console.log(dataColumn);

        await axios
            .post(url + `webformusuario/update/${ruta}`, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                Data: dataColumn,
            })
            .then(function (response) {
                // console.log(response);
                setAllColumns(response.data);
                setMessageAlert(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const defaultSetting = (e) => {
        setAllColumns([...e]);
        setMessageAlert(true);
        setTimeout(() => {
            setMessageAlert(false);
        }, 1000);
    };

    return {
        ruta,
        data,
        loading,
        allColumns,
        messageAlert,
        sortConfig,
        applyChanges,
        saveChanges,
        setAllColumns,
        defaultSetting,
        setSortConfig,
        busqueda,
        acciones,
        setBusqueda,
        filterResult,
        paginationFinish,
    };
};
