import { useState, useEffect } from "react";
import { AppRoute } from "./router/AppRoute";
import UserContext from "./context/UserContext";
import { urlEncode } from "./hooks/useUrlFunction";

//assets
import "./index.css";
import "animate.css";

function PWGestion() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [workstationActive, setWorkstationActive] = useState(
        JSON.parse(localStorage.getItem("workstation"))
    ); 
    const [versionDb, setVersionDb] = useState(JSON.parse(localStorage.getItem("version")));
    const [resourceCache, setResourceCache] = useState(localStorage.getItem("resourceCache"));
    var headers = {
        "Content-Type": "application/json",
    };
    const [resourcePagination, setResourcePagination] = useState(JSON.parse(localStorage.getItem('resourcePagination')))
    var findHostname = window.location.hostname.split(".");
    // url 
    var url = urlEncode(findHostname[0]);
 
    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                headers,
                url,
                workstationActive,
                setWorkstationActive,
                versionDb,
                setVersionDb,
                resourcePagination,
                setResourcePagination,
                resourceCache,
                setResourceCache,
            }}
        >
            <>
                <AppRoute />
                {/* {!value ? <Login /> : <Landing />} */}
            </>
        </UserContext.Provider>
    );
}

export default PWGestion;
