import { useState, useContext, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import ModalContext from "../../../../context/ModalContext";
import AlertContext from "../../../../context/AlertContext";
import Moment from "moment";
import "../index.css";
import { Alert } from "../../../Alerts";

export const ThirdComponent = ({ nextStep, prevStep }) => {
    const {
        data,
        setData,
        caracteristicasVariantes,
        setCaracteristicasVariantes,
    } = useContext(ModalContext);

    const { setStatusCode, setResponseText } = useContext(AlertContext);

    const [stateSV, setStateSV] = useState("");

    const vCombo = caracteristicasVariantes
        .filter((vc) => vc.ModoDisenioApi === 1)
        .map((vc) => vc.Variantes);

    const comboSelected = (objVariantes) => {
        const searchSelected = objVariantes.filter(
            (obv) => obv.ChequeadoAPI == true
        );

        if (searchSelected.length >= 1) {
            return searchSelected[0].Nombre;
        }
    };

    const vMultiple = caracteristicasVariantes
        .filter((fmv) => fmv.ModoDisenioApi === 2)
        .map((fmv) => fmv.Variantes);

    const vCaracCero = caracteristicasVariantes
        .filter((vcc) => vcc.ModoDisenioApi === 0)
        .map((vcc) => {
            return vcc.Variantes[0];
        });

    const checkVariablesExisten = () => {
        var varM = [];
        var varC = [];
        var varZ = [];

        if (vMultiple.length !== 0) {
            for (let i = 0; i < vMultiple.length; i++) {
                vMultiple[i].filter((f) => {
                    if (f.ChequeadoAPI === true) {
                        varM = varM.concat(f);
                    }
                });
            }
        }
        if (vCombo.length !== 0) {
            for (let i = 0; i < vCombo.length; i++) {
                vCombo[i].filter((f) => {
                    if (f.ChequeadoAPI === true) {
                        varC = varC.concat(f);
                    }
                });
            }
        }
        if (vCaracCero.length !== 0) {
            const vCeroExist = vCaracCero.filter((fcc) => {
                return fcc.Nombre !== "";
            });
            varZ = vCeroExist;
        }

        const variantes = varM.concat(varC, varZ);
        if (variantes.length !== 0) {
            setData({
                ...data,
                Operacion: {
                    ...data.Operacion,
                    Caracteristicas: [...variantes],
                },
            });
        }
    };

    useEffect(() => {
        checkVariablesExisten();
    }, [caracteristicasVariantes]);

    const handleChangeCombo = ({ target }) => {
        const { id, value } = target;

        const getObj = caracteristicasVariantes.find(
            (f) => f.CaracteristicaKey == id
        );

        comboSelected(getObj.Variantes);

        getObj.Variantes.map((mov) => {
            return (mov.ChequeadoAPI = false);
        });

        getObj.Variantes.find((fmv) => {
            if (fmv.Nombre === value) {
                return (fmv.ChequeadoAPI = true);
            } else {
                return (fmv.ChequeadoAPI = false);
            }
        });

        const exist = caracteristicasVariantes.some(
            (sc) => sc.CaracteristicaKey == id
        );

        if (exist) {
            for (let i = 0; i < caracteristicasVariantes.length; i++) {
                if (caracteristicasVariantes[i].CaracteristicaKey == id) {
                    setCaracteristicasVariantes((caracteristicasVariantes) => [
                        ...caracteristicasVariantes,
                    ]);
                }
            }
        }
    };

    const handleChangeCSV = ({ target }) => {
        const { id, value, type } = target;

        const getObj = caracteristicasVariantes.find(
            (f) => f.CaracteristicaKey == id
        );

        if (type === "checkbox") {
            if (getObj.Variantes[0].Nombre === "0") {
                getObj.Variantes[0].Nombre = "1";
                getObj.Variantes[0].ChequeadoAPI = true;
            } else {
                getObj.Variantes[0].Nombre = "0";
                getObj.Variantes[0].ChequeadoAPI = false;
            }
        } else if (type === "date") {
            getObj.Variantes[0].Nombre = Moment(value, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
            );
            getObj.Variantes[0].ChequeadoAPI = true;
        } else if (type === "text" || type === "number") {
            if (getObj.Variantes[0].CaracteristicaKey === parseInt(id)) {
                getObj.Variantes[0].Nombre = value;
                if (getObj.Variantes[0].Nombre === "") {
                    getObj.Variantes[0].ChequeadoAPI = false;
                } else {
                    getObj.Variantes[0].ChequeadoAPI = true;
                }
            }
        }

        const exist = caracteristicasVariantes.some(
            (sc) => sc.CaracteristicaKey == id
        );

        if (exist) {
            for (let i = 0; i < caracteristicasVariantes.length; i++) {
                if (caracteristicasVariantes[i].CaracteristicaKey == id) {
                    setCaracteristicasVariantes((caracteristicasVariantes) => [
                        ...caracteristicasVariantes,
                    ]);
                }
            }
        }
    };

    const handleChangeMultiple = ({ target }) => {
        const { id, checked, name } = target;

        const getObj = caracteristicasVariantes.find(
            (f) => f.CaracteristicaKey == name
        );

        getObj.Variantes.find((fc) => {
            if (
                fc.ChequeadoAPI !== checked &&
                fc.CaracteristicaVarianteKey == id
            ) {
                return (fc.ChequeadoAPI = checked);
            }
        });
        // console.log(getObj);
        const exist = caracteristicasVariantes.some(
            (sc) => sc.CaracteristicaKey == name
        );

        if (exist) {
            for (let i = 0; i < caracteristicasVariantes.length; i++) {
                if (caracteristicasVariantes[i].CaracteristicaKey == name) {
                    setCaracteristicasVariantes((caracteristicasVariantes) => [
                        ...caracteristicasVariantes,
                    ]);
                }
            }
        }

        // if (checked) {
        //     setCaracteristicasVariantes((caracteristicasVariantes) => [
        //         ...caracteristicasVariantes,
        //         modifyVariant,
        //     ]);
        // } else {
        //     setCaracteristicasVariantes((caracteristicasVariantes) => [
        //         ...caracteristicasVariantes.filter((fs) => {
        //             return fs !== modifyVariant;
        //         }),
        //     ]);
        // }
        // console.log(caracteristicasVariantes);
    };

    const handleValidation = () => {
        var caracteristicasForm = 0;

        const caracteristicasRequeridas = caracteristicasVariantes.filter(
            (fcv) => fcv.Requerida === true
        );
        const variantesRequeridas = caracteristicasRequeridas.map(
            (mcv) => mcv.Variantes
        );

        //validation all required
        for (let i = 0; i < variantesRequeridas.length; i++) {
            const variantes = variantesRequeridas[i].filter((fv) => {
                return fv.ChequeadoAPI === true;
            });
            caracteristicasForm = caracteristicasForm + variantes.length;
        }

        // console.log(data.Operacion.Caracteristicas);
        if (caracteristicasForm === variantesRequeridas.length) {
            nextStep();
        } else {
            setStatusCode(406);
            setResponseText(
                "Hay caracteristicas requeridas sin completar, por favor reviselas"
            );
            setTimeout(() => {
                setResponseText("");
            }, 3000);
        }
    };

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-between">
                    <button
                        className={`bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={prevStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.85Z"
                            ></path>
                        </svg>
                    </button>
                    <button
                        className={`ml-15 bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={handleValidation}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="text-center">
                {caracteristicasVariantes ? (
                    <>
                        <div className="px-4 md:h-60 h-96 sm:px-6 md:mb-0 mb-5 lg:px-8 border border-transparent">
                            <div className="mt-2 flow-root">
                                <div className="-mx-4 sm:-mx-6 lg:-mx-8">
                                    <div className="overflow-auto md:h-52 h-96">
                                        <Alert />
                                        <Dialog.Title
                                            as="h3"
                                            className="text-left text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Características disponibles
                                            <br />
                                            <p className="text-xs pb-2">
                                                campos requeridos (*)
                                            </p>
                                        </Dialog.Title>
                                        <hr />

                                        {caracteristicasVariantes.map((c, i) => (
                                            <div
                                                key={i}
                                                className="flex justify-between py-1"
                                            >
                                                <div>
                                                    <p className="font-semibold text-gray-700 text-sm">
                                                        {c.Nombre}{" "}
                                                        {c.Requerida && "(*)"}
                                                    </p>
                                                </div>
                                                <div>
                                                    {c.ModoDisenioApi == 1 ? (
                                                        <select
                                                            className="rounded-md text-xs"
                                                            id={c.CaracteristicaKey}
                                                            value={comboSelected(
                                                                c.Variantes
                                                            )}
                                                            onChange={handleChangeCombo}
                                                        >
                                                            <option value="">
                                                                Seleccione una opción
                                                            </option>
                                                            {c.Variantes.map(
                                                                (vc, i) => (
                                                                    <option
                                                                        key={i}
                                                                        name={
                                                                            vc.CaracteristicaVarianteKey
                                                                        }
                                                                        value={
                                                                            vc.Nombre
                                                                        }
                                                                    >
                                                                        {vc.Nombre}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : c.ModoDisenioApi === 2 ? (
                                                        <div className="pr-8 bg-gray-100 pl-2 border space-y-2">
                                                            {c.Variantes.map((v, i) => (
                                                                <div
                                                                    key={i}
                                                                    className="relative flex items-start"
                                                                >
                                                                    <div className="flex h-5 items-center">
                                                                        <input
                                                                            id={
                                                                                v.CaracteristicaVarianteKey
                                                                            }
                                                                            name={
                                                                                v.CaracteristicaKey
                                                                            }
                                                                            onChange={
                                                                                handleChangeMultiple
                                                                            }
                                                                            checked={
                                                                                v.ChequeadoAPI
                                                                            }
                                                                            type="checkbox"
                                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600"
                                                                        />
                                                                    </div>
                                                                    <div className="ml-3 text-sm">
                                                                        <label
                                                                            htmlFor="comments"
                                                                            className="font-medium text-gray-700"
                                                                        >
                                                                            {v.Nombre}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        {
                                                            Fecha: (
                                                                <input
                                                                    type="date"
                                                                    required={
                                                                        c.Requerida
                                                                    }
                                                                    id={
                                                                        c.Variantes[0]
                                                                            .CaracteristicaKey
                                                                    }
                                                                    value={
                                                                        c.Variantes[0]
                                                                            .Nombre
                                                                            ? Moment(
                                                                                c
                                                                                    .Variantes[0]
                                                                                    .Nombre,
                                                                                "DD/MM/YYYY"
                                                                            ).format(
                                                                                "YYYY-MM-DD"
                                                                            )
                                                                            : ""
                                                                    }
                                                                    onChange={
                                                                        handleChangeCSV
                                                                    }
                                                                    className="rounded-md text-xs"
                                                                />
                                                            ),
                                                            Texto: (
                                                                <input
                                                                    type="text"
                                                                    required={
                                                                        c.Requerida
                                                                    }
                                                                    autoComplete="off"
                                                                    id={
                                                                        c.Variantes[0]
                                                                            .CaracteristicaKey
                                                                    }
                                                                    value={
                                                                        c.Variantes[0]
                                                                            .Nombre
                                                                            ? c
                                                                                .Variantes[0]
                                                                                .Nombre
                                                                            : stateSV
                                                                    }
                                                                    onChange={
                                                                        handleChangeCSV
                                                                    }
                                                                    className="rounded-md text-xs"
                                                                    max="200"
                                                                />
                                                            ),
                                                            Entero: (
                                                                <input
                                                                    type="number"
                                                                    required={
                                                                        c.Requerida
                                                                    }
                                                                    id={
                                                                        c.Variantes[0]
                                                                            .CaracteristicaKey
                                                                    }
                                                                    name={
                                                                        c.Variantes[0]
                                                                            .Nombre
                                                                    }
                                                                    value={
                                                                        c.Variantes[0]
                                                                            .Nombre
                                                                    }
                                                                    onChange={
                                                                        handleChangeCSV
                                                                    }
                                                                    className="rounded-md text-xs"
                                                                />
                                                            ),
                                                            Numérico: (
                                                                <input
                                                                    type="number"
                                                                    required={
                                                                        c.Requerida
                                                                    }
                                                                    id={
                                                                        c.Variantes[0]
                                                                            .CaracteristicaKey
                                                                    }
                                                                    value={
                                                                        c.Variantes[0]
                                                                            .Nombre
                                                                            ? c
                                                                                .Variantes[0]
                                                                                .Nombre
                                                                            : ""
                                                                    }
                                                                    onChange={
                                                                        handleChangeCSV
                                                                    }
                                                                    className="rounded-md text-xs"
                                                                />
                                                            ),
                                                            Binario: (
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={
                                                                            c
                                                                                .Variantes[0]
                                                                                .CaracteristicaKey
                                                                        }
                                                                        name={
                                                                            c
                                                                                .Variantes[0]
                                                                                .Nombre
                                                                        }
                                                                        value={
                                                                            c
                                                                                .Variantes[0]
                                                                                .Nombre
                                                                                ? c
                                                                                    .Variantes[0]
                                                                                    .Nombre
                                                                                : "0"
                                                                        }
                                                                        checked={
                                                                            c
                                                                                .Variantes[0]
                                                                                .ChequeadoAPI
                                                                        }
                                                                        onChange={
                                                                            handleChangeCSV
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            ),
                                                            "Fecha y Hora": (
                                                                <input
                                                                    type="datetime-local"
                                                                    required={
                                                                        c.Requerida
                                                                    }
                                                                    name=""
                                                                    id=""
                                                                />
                                                            ),
                                                            Hora: (
                                                                <input
                                                                    type="time"
                                                                    required={
                                                                        c.Requerida
                                                                    }
                                                                    name=""
                                                                    id=""
                                                                />
                                                            ),
                                                        }[c.DataType]
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                            No hay características cargadas
                        </h3>
                    </>
                )}
            </div>
        </>
    );
};
