export const urlEncode = (host) => {
    let url = "";
    if(host != 'localhost'){
        url = `https://${host}.pwrad.com.ar/`;
    }else{
        url = "https://demo.pwrad.com.ar/";
    }
    return url;
}

