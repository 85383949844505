// 200: RS_RESULTADO_OK,
// 201: RS_RESULTADO_CREADO,
// 204: RS_RESULTADO_NO_CONTENT,
// 400: RS_BAD_REQUEST,
// 401: RS_UNAUTHORIZED,
// 403: RS_FORBIDDEN,
// 404: RS_NOT_FOUND,
// 405: RS_METHOD_NOT_ALLOWED,
// 406: RS_NOT_ACCEPTABLE,
// 409: RS_CONFLICT,
// 415: RS_UNSUPPORTED_MEDIA_TYPE,
// 500: RS_INTERNAL_SERVER_ERROR,

import { useContext } from "react";
import AlertContext from "../../context/AlertContext";

export const Alert = () => {
    const { statusCode, responseText, setResponseText } =
        useContext(AlertContext);

    const colorStatusCode = (statusCode) => {
        switch (statusCode) {
            case 200:
            case 201:
            case 204:
                return "bg-green-50 text-green-500 hover:bg-green-100";

            case 400:
            case 401:
            case 403:
            case 404:
            case 405:
            case 406:
            case 409:
            case 415:
                return "bg-yellow-50 text-yellow-500 hover:bg-yellow-100";

            case 500:
                return "bg-red-50 text-red-500 hover:bg-red-100";

            default:
                return "bg-gray-50 text-gray-500 hover:bg-gray-100";
        }
    };

    const iconByStatusCode = (statusCode) => {
        switch (statusCode) {
            case 200:
            case 201:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-green-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                );

            case 204:
            case 400:
            case 401:
            case 403:
            case 404:
            case 405:
            case 406:
            case 409:
            case 415:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-yellow-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                );

            case 500:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-red-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                );

            default:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                );
        }
    };

    const textByStatusCode = (statusCode) => {
        switch (statusCode) {
            case 200:
            case 201:
            case 204:
                return "text-green-800";

            case 400:
            case 401:
            case 403:
            case 404:
            case 405:
            case 406:
            case 409:
            case 415:
                return "text-yellow-800";

            case 500:
                return "text-red-800";

            default:
                return "text-gray-800";
        }
    };

    const backgroundByStatusCode = (statusCode) => {
        switch (statusCode) {
            case 200:
            case 201:
            case 204:
                return "bg-green-50 border-l-4 border-green-400";

            case 400:
            case 401:
            case 403:
            case 404:
            case 405:
            case 406:
            case 409:
            case 415:
                return "bg-yellow-50 border-l-4 border-yellow-400";

            case 500:
                return "bg-red-50 border-l-4 border-red-400";

            default:
                return "bg-gray-50 border-l-4 border-gray-400";
        }
    };

    return (
        <>
            {responseText === "" ? (
                ""
            ) : (
                <div
                    className={`absolute z-10 right-0 top-10 mt-px shadow-xl rounded-md p-3 ${backgroundByStatusCode(
                        statusCode
                    )}`}
                >
                    <div className="flex">
                        <div className="flex-shrink-0">
                            {iconByStatusCode(statusCode)}
                        </div>
                        <div className="ml-3">
                            <p
                                className={`text-sm font-medium ${textByStatusCode(
                                    statusCode
                                )}`}
                            >
                                {responseText && responseText}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className={`inline-flex rounded-md p-1.5 focus:outline-none ${colorStatusCode(
                                        statusCode
                                    )}`}
                                    onClick={() => setResponseText("")}
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
