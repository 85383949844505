import React, { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import FilterContext from "../../context/FilterContext";
import { FilterByInputRangeGeneric } from "./FilterByInputRangeGeneric";
import { FilterBySelectGeneric } from "./FilterBySelectGeneric";
import { FilterByInputGeneric } from "./FilterByInputGeneric";
import { FilterByRadioButtonGeneric } from "./FilterByRadioButtonGeneric";
import { FilterByCheckboxGeneric } from "./FilterByCheckboxGeneric";
import { FilterBySelector } from "./FilterBySelector";

export const GenericFilter = ({ data = [], getFiltersData, closeFiltro, refresh }) => {
    const [open, setOpen] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const { acciones, queryObj, dispatchFilterReducer } =
        useContext(FilterContext);

    //definición de todos los componentes existentes
    const Components = {
        FilterByInputRangeGeneric: FilterByInputRangeGeneric,
        FilterBySelectGeneric: FilterBySelectGeneric,
        FilterByInputGeneric: FilterByInputGeneric,
        FilterByRadioButtonGeneric: FilterByRadioButtonGeneric,
        FilterByCheckboxGeneric: FilterByCheckboxGeneric,
        FilterBySelector: FilterBySelector,
    };

    //verifica los filtros cuando se cierra el sidebar si encuentra alguno
    //que no fue apfirstlicado salta ventana de alerta
    const verifyApliedFilter = () => {
        const filtrosNoAplicados = queryObj.map((query) => {
            const coincidencia = data.find((filtro) => {
                if (filtro.viewField === query.filterField) {
                    if (filtro.inicial === 1) {
                        return false;
                    }
                    if ("aplicado" in query && !query.aplicado) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            return coincidencia !== undefined;
        });
        if (filtrosNoAplicados.includes(true)) {
            setAlertModal(true);
        } else {
            setOpen(!open);
        }
    };

    //funcion para eliminar todos los filtros
    // const deleteAllFilters = () => {
    //     setAlertModal(true)
    //     const action = {
    //         type: "resetAllFilters",
    //     };
    //     dispatchFilterReducer(action);
    //     closeFiltro();
    // };

    //funcion que acepta todos los filtros
    const acceptAllFilters = () => {
        const action = {
            type: "validateFilters"
        }
        dispatchFilterReducer(action);
        getFiltersData(); //viene desde el componente que llamo a este archivo
        setOpen(!open);
    };

    //boton que se ejecuta al aceptar la ventana de alerta
    const saveFilters = () => {
        const action = {
            type: "validateFilters"
        }
        dispatchFilterReducer(action);
        setAlertModal(false);
        setOpen(!open);
        closeFiltro();
        // getFiltersData(); //viene desde el componente que llamo a este archivo
        // closeFiltro();
    };

    //constante que muestra los filtros aplicados o que son iniciales en 1
    const filtrosAplicados = data.filter(
        (filtro) => "value" in filtro && filtro.value || filtro.inicial === 1
    );
    // console.log(filtrosAplicados);

    return (
        <>
            <button
                onClick={() => setOpen(!open)}
                type="button"
                className="relative inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-700"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7 md:mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    />
                </svg>
                <span className="md:block hidden">Filtros</span>
                {filtrosAplicados.length >= 1 && (
                    <span className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                )}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={verifyApliedFilter}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-2xl font-bold text-gray-900">
                                                        {filtrosAplicados.length ===
                                                        0
                                                            ? `Filtros disponibles`
                                                            : `Filtros aplicados`}
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            className="relative rounded-md bg-white text-gray-400"
                                                            onClick={
                                                                verifyApliedFilter
                                                            }
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">
                                                                Close panel
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18L18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="w-full py-1">
                                                    {filtrosAplicados.map(
                                                        (f, i) => (
                                                            <p
                                                                key={i}
                                                                className="text-xs"
                                                            >{`${
                                                                f.title +
                                                                ": " +
                                                                `${
                                                                    f.value === undefined ? 
                                                                        f.displayvalue === "" ? "sin confirmar" : f.displayvalue
                                                                        : typeof f.value === "string" ? f.value : f.value && f.value.length
                                                                }`
                                                            }`}</p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="relative mt-2 flex-1 px-4 sm:px-6">
                                                <p className="text-sm text-gray-700 text-justify">
                                                    {" "}
                                                </p>
                                                <div className="my-4 border-t border-gray-200">
                                                    {data.length > 0 ? (
                                                        data.map((item, i) => {
                                                            if (
                                                                typeof Components[
                                                                    item
                                                                        .component
                                                                ] !==
                                                                "undefined"
                                                            ) {
                                                                return React.createElement(
                                                                    Components[
                                                                        item
                                                                            .component
                                                                    ],
                                                                    {
                                                                        ...item,
                                                                        key: i,
                                                                    }
                                                                );
                                                            }
                                                        })
                                                    ) : (
                                                        <div>
                                                            No hay filtros
                                                            creados para este
                                                            tipo de operacion
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {data.length > 0 ? (
                                                <div className="flex flex-shrink-0 justify-end px-4 mt-2 pb-4">
                                                    <button
                                                        onClick={
                                                            acceptAllFilters
                                                        }
                                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 shadow-xl hover:bg-blue-800 py-2 px-4 text-sm font-medium text-white focus:outline-none"
                                                    >
                                                        Aplicar filtros
                                                    </button>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {alertModal && (
                <Transition.Root show={alertModal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={setAlertModal}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="md:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationIcon
                                                    className="h-6 w-6 text-red-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900"
                                                >
                                                    Tiene filtros que modificó y
                                                    no aplicó
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Recientemente modificó
                                                        un filtro y no aplicó
                                                        los cambios. Si acepta
                                                        la ventana se <b>perderán </b>
                                                        todos los filtros
                                                        modificados. ¿Está
                                                        seguro de continuar?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                                onClick={saveFilters}
                                            >
                                                Aceptar
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() =>
                                                    setAlertModal(false)
                                                }
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            )}
        </>
    );
};
