import { useState, useContext } from "react";
import { useActions } from "../../../../permissions/useActions";
import Moment from "moment";
import ModalMenuOption from "./ModalMenuOption";
import ModalContext from "../../../../context/ModalContext";
import { Popover } from "@headlessui/react";

export const ArticulosItem = ({ item, itemIdx }) => {
    const [menuOptions, setMenuOptions] = useState(false);
    const { data, setData } = useContext(ModalContext);
    const { isAllowed } = useActions();

    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(item.ItemOperacion.Item.Nombre);
    const [updatedObject, setUpdatedObject] = useState(item);

    const handleDescription = ({ target }) => {
        const { value } = target;
        setNewValue(value);
        setUpdatedObject({
            ...updatedObject,
            ItemOperacion: {
                ...updatedObject.ItemOperacion,
                Item: {
                    ...updatedObject.ItemOperacion.Item,
                    Nombre: value,
                },
            },
        });
    };

    const confirmNewDescription = () => {
        setIsEditing(false);
        const updateData = {
            ...data,
            Items: data.Items.map((item, index) =>
                index === itemIdx ? updatedObject : { ...item }
            ),
        };
        setData(updateData);
    };

    const cancelNewDescription = () => {
        setIsEditing(!isEditing);
        setNewValue(item.ItemOperacion.Item.Nombre);
    };

    console.log(item);

    return (
        <tr
            className={`border ${
                itemIdx % 2 === 0 ? undefined : "bg-gray-200"
            }`}
        >
            {data.ContratoOperacion.CargaItemsXHora && (
                <>
                    <td className="hidden md:table-cell whitespace-nowrap py-1 text-sm text-gray-500 font-medium sm:pl-6">
                        {Moment(item.FechaInicio).format("DD/MM/YY - HH:mm")}
                    </td>
                    <td className="hidden md:table-cell whitespace-nowrap py-1 text-sm text-gray-500 font-medium">
                        {Moment(item.FechaCierre).format("DD/MM/YY - HH:mm")}
                    </td>
                </>
            )}

            <td className="hidden md:table-cell whitespace-nowrap py-1 text-sm text-center text-gray-500">
                {data.ContratoOperacion.CargaItemsXHora
                    ? item.Horas
                    : item.ItemOperacion.Cantidad}
            </td>
            <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                <div className="md:inline-flex hidden items-center w-11/12">
                    {isEditing ? (
                        <div className="w-11/12 focus:outline-none">
                            <input
                                type="text"
                                name="Nombre"
                                id="Nombre"
                                className="block -my-1 w-full rounded-lg bg-gray-50 sm:text-sm"
                                onChange={handleDescription}
                                value={newValue}
                                autoComplete="off"
                            />
                        </div>
                    ) : (
                        newValue
                    )}
                    {isAllowed(
                        "OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEM"
                    ) && (
                        <div className="inline-flex items-center ml-3">
                            {isEditing ? (
                                <div className="inline-flex items-center space-x-1">
                                    <button onClick={confirmNewDescription}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    </button>
                                    <button onClick={cancelNewDescription}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 ml-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                        />
                                    </svg>
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <span className="md:hidden">
                    {item.ItemOperacion.Item.Nombre}
                </span>
                <dl className="md:hidden">
                    {data.ContratoOperacion.CargaItemsXHora && (
                        <>
                            <dd className="mt-1 truncate text-gray-700">
                                <span className="font-bold text-xs">Inicio </span>
                                {Moment(item.FechaInicio).format("DD/MM/YY - HH:mm")}
                            </dd>
                            <dd className="mt-1 truncate text-gray-700">
                                <span className="font-bold text-xs">Fin </span>
                                {Moment(item.FechaCierre).format("DD/MM/YY - HH:mm")}
                            </dd>
                        </>
                    )}
                    <dd className="mt-1 truncate text-gray-700">
                        <span className="font-bold text-xs">Cant </span>
                        {data.ContratoOperacion.CargaItemsXHora
                            ? item.Horas
                            : item.ItemOperacion.Cantidad}
                    </dd>
                    <dd className="mt-1 truncate text-gray-700">
                        <span className="font-bold text-xs">Unitario </span>
                        {parseFloat(item.ItemOperacion.PrecioUnitarioNG).toFixed(2)}
                    </dd>
                    <dd className="mt-1 truncate text-gray-700">
                        <span className="font-bold text-xs">Total NG </span>
                        {parseFloat(item.ItemOperacion.PrecioTotalNG).toFixed(2)}
                    </dd>
                    <dd className="mt-1 truncate text-gray-700">
                        <span className="font-bold text-xs">Maniobra </span>
                        {item.ItemOperacion.NotaDescripcion}
                    </dd>
                </dl>
            </td>
            <td className="hidden md:table-cell whitespace-nowrap py-1 text-sm text-gray-500 truncate text-ellipsis">
                {parseFloat(item.ItemOperacion.PrecioUnitarioNG).toFixed(2)}
            </td>
            <td className="hidden md:table-cell whitespace-nowrap py-1 text-sm text-gray-500 truncate text-ellipsis">
                {parseFloat(item.ItemOperacion.PrecioTotalNG).toFixed(2)}
            </td>
            <td className="hidden md:table-cell whitespace-nowrap py-1 text-sm text-gray-500 truncate text-ellipsis">
                {item.ItemOperacion.NotaDescripcion}
            </td>
            <td className="relative whitespace-nowrap py-1 pl-1 md:pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:inline-flex items-center">
                <Popover className="relative">
                    <Popover.Button className="focus:bg-gray-200 bg-white rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 hover:text-indigo-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                        </svg>
                    </Popover.Button>

                    <Popover.Panel className="absolute right-0 z-10 -mt-1.5 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none">
                        {({ close }) => (
                            <div className="p-1">
                                <ModalMenuOption
                                    action="del_article"
                                    actionText={"Eliminar item"}
                                    indexItem={itemIdx}
                                    handleAction={setMenuOptions}
                                />
                                {data.ContratoOperacion.CargaItemsXHora ? (
                                    <ModalMenuOption
                                        action="change_period_article"
                                        actionText={
                                            "Modificar el rango horario del item"
                                        }
                                        indexItem={itemIdx}
                                        handleAction={setMenuOptions}
                                    />
                                ) : (
                                    <ModalMenuOption
                                        action="change_amount_article"
                                        actionText={
                                            "Modificar la cantidad del item"
                                        }
                                        indexItem={itemIdx}
                                        handleAction={setMenuOptions}
                                    />
                                )}
                                <ModalMenuOption
                                    action="article_assign_well_used"
                                    actionText={"Asignar bien de uso al item"}
                                    indexItem={itemIdx}
                                    handleAction={setMenuOptions}
                                />
                            </div>
                        )}
                    </Popover.Panel>
                </Popover>
            </td>
        </tr>
    );
};
