import { Fragment } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";

import { Table } from "../../TableComponent";

import { useSelectorArticulo } from "./useSelectorArticulo";
import { Alert } from "../../Alerts";

export const SelectorArticulo = ({ onAddArticle, listaPrecioKey = 1 }) => {
    const {
        open,
        alerta,
        busqueda,
        setBusqueda,
        setOpen,
        loading,
        setAlerta,
        allColumns,
        getArticulos,
        selectArticle,
        cancelButtonRef,
        sortConfig,
        setSortConfig,
        filterResult,
        close,
        acceptButtonRef,
        paginationFinish,
        searchSmart,
        avanzada,
        setAvanzada,
        isCache,
    } = useSelectorArticulo(onAddArticle, listaPrecioKey);

    return (
        <>
            <button
                type="button"
                onClick={() => setOpen(true)}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
            >
                Buscar artículo
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="md:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-5/6 sm:p-6">
                                    <div>
                                        {alerta && (
                                            <div className="rounded-md bg-yellow-50 p-4 absolute top-9 left-80 sm:w-2/6 z-20 ">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6 text-yellow-500"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-yellow-800">
                                                            {alerta}
                                                        </p>
                                                    </div>
                                                    <div className="ml-auto pl-3">
                                                        <div className="-mx-1.5 -my-1.5">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    setAlerta(
                                                                        ""
                                                                    )
                                                                }
                                                                className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none"
                                                            >
                                                                <span className="sr-only">
                                                                    Close
                                                                </span>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <Alert />
                                        <div className="my-3 text-left sm:mb-5 md:inline-flex w-full justify-between items-center">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-xl leading-6 text-gray-900 font-semibold"
                                            >
                                                Listado de artículos
                                            </Dialog.Title>
                                            {paginationFinish && !loading && (
                                                <div className="w-full inline-flex items-center md:justify-end">
                                                    <p className="text-xs italic text-gray-400 mr-2">
                                                        Sus articulos se están
                                                        cargando
                                                    </p>
                                                    <svg
                                                        className="w-6 h-6 text-gray-300 animate-spin mr-3"
                                                        viewBox="0 0 64 64"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                                            stroke="currentColor"
                                                            strokeWidth="5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        ></path>
                                                        <path
                                                            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                                            stroke="currentColor"
                                                            strokeWidth="5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="text-gray-900"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            )}
                                            <div className="">
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        value={busqueda}
                                                        onChange={(e) => setBusqueda(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            {isCache && (
                                                <div className="mt-2 inline-flex rounded-md shadow-sm">
                                                    <Disclosure>
                                                        {({ open }) => (
                                                            <>
                                                                {open ? (
                                                                    <Disclosure.Button onClick={() => searchSmart(avanzada)} className="order-last relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                                        Buscar
                                                                    </Disclosure.Button>
                                                                    ) : (
                                                                    <Disclosure.Button className="order-last relative -ml-px inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                                        Busqueda Avanzada
                                                                    </Disclosure.Button>
                                                                )}
                                                                
                                                                <Transition
                                                                    enter="transition duration-100 ease-out"
                                                                    enterFrom="transform scale-95 opacity-0"
                                                                    enterTo="transform scale-100 opacity-100"
                                                                    leave="transition duration-75 ease-out"
                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                    leaveTo="transform scale-95 opacity-0"
                                                                >
                                                                    <Disclosure.Panel className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                        {({ close }) => (
                                                                            <>
                                                                                <div className=" absolute inset-y-0 left-0 flex items-center pl-3">
                                                                                    <button onClick={close}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    name="busqueda"
                                                                                    id="busqueda"
                                                                                    onChange={(e) => setAvanzada(e.target.value)}
                                                                                    value={avanzada}
                                                                                    className="block w-full rounded-s-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                    placeholder="Búsqueda . . ."
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Disclosure.Panel>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Disclosure>
                                                </div>
                                            )}
                                            {/* <div className="max-w-lg w-full lg:max-w-xs">
                                                <label
                                                    htmlFor="search"
                                                    className="sr-only"
                                                >
                                                    Busqueda
                                                </label>
                                                <div className="mt-2 flex rounded-md shadow-sm">
                                                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-400"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input
                                                            type="search"
                                                            value={busqueda}
                                                            onChange={(e) =>
                                                                setBusqueda(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="Búsqueda inteligente"
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            searchSmart(
                                                                busqueda
                                                            )
                                                        }
                                                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    >
                                                        Buscar
                                                    </button>
                                                </div>
                                            </div> */}
                                        </div>

                                        {loading ? (
                                            <div className="mt-52 flex-col h-full inline-flex items-center justify-center mx-auto space-y-5 w-full">
                                                <div className="-mt-52 w-20 h-20 rounded-full animate-spin border-x-8 border-solid border-green-600 border-t-transparent shadow-md"></div>
                                                <p className="text-center">
                                                    Cargando . . .
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="h-96 overflow-y-auto">
                                                <Table
                                                    data={filterResult}
                                                    column={allColumns}
                                                    view={"single"}
                                                    sortConfig={sortConfig}
                                                    setSortConfig={
                                                        setSortConfig
                                                    }
                                                    busqueda={busqueda}
                                                    paginationFinish={
                                                        paginationFinish
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        ref={acceptButtonRef}
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
                                    >
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                                            onClick={selectArticle}
                                        >
                                            Seleccionar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={close}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
