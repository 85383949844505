import React, { useState, Fragment, useContext, useEffect } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";

//context
import FilterContext from "../../context/FilterContext";
import UserContext from "../../context/UserContext";

//utils
import './index.css'
import { TripleMaze } from "react-spinner-animated";
import { Table } from "../TableComponent";
import AlertContext from "../../context/AlertContext";

export const FilterBySelector = ({
    selector,
    displayvalue,
    operacion,
    viewField,
    tipoFiltro,
    aplicado,
    title,
    deleteFilter
}) => {
    const [open, setOpen] = useState(false);
    const [valueSelected, setValueSelected] = useState(null);
    const [valuesSelected, setValuesSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const [allColumns, setAllColumns] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const filterResult = data.filter((item) => item.Filtered.toLowerCase().includes(busqueda.toLowerCase()));
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const [isAplicado, setIsAplicado] = useState(null)
    const { acciones, dispatchFilterReducer, queryObj } = useContext(FilterContext);
    const { url, workstationActive, headers, user } = useContext(UserContext);
    const { setResponseText, setStatusCode } = useContext(AlertContext);
    const { titulo, tipo, nombre, formato, origenDatos, viewField: selectorView } = selector;

    // funcion para enviar a Table -> TableRow para seleccion
    const handleSelect = (id) => {
        if (tipo === "multiple") {
            if (valuesSelected.includes(id)) {
                // Si ya está, quitarlo de la lista
                setValuesSelected(valuesSelected.filter((selectedId) => selectedId != id));
            } else {
                // Si no está, agregarlo a la lista
                setValuesSelected([...valuesSelected, id]);
            }
        } else {
            setValueSelected(id);
        }
    };

    // función cuando da click en label para abrir modal
    const openFilterRequest = async () => {
        setOpen(!open);
        setLoading(true);
        await axios
            .post(url + origenDatos, {
                Usuario: user.UsuarioKey,
                Workstation: workstationActive.WorkstationKey,
                RequiereContexto: true,
            })
            .then(function (res) {
                // console.log(res);
                setAllColumns(res.data.WebForms);
                setData(res.data.Data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response != undefined) {
                    setLoading(false);
                    setData([]);
                    if (error.response.status === 404) {
                        setResponseText(
                            "Error de petición. Consulte con administración"
                        );
                        setTimeout(() => {
                            setResponseText("");
                        }, 3000);
                    } else {
                        setResponseText(error.response.data.Motivo);
                        setTimeout(() => {
                            setResponseText("");
                        }, 3000);
                    }
                    setStatusCode(error.response.status);
                } else {
                    setLoading(false);
                    setData([]);
                    setResponseText(
                        "Hubo un error crítico. Consulte con administración"
                    );
                    setTimeout(() => {
                        setResponseText("");
                    }, 3000);
                }
            });
    };

    //useEffects
    useEffect(() => {
        //blanquea mensaje cuando selecciona un item
        if(message != ''){
            setMessage('')
    }
    }, [valueSelected, valuesSelected])
    
    useEffect(() => {
        //inicia el buscador en vacio cada vez que abre modal
        setBusqueda("");
    }, [open]);
    
    useEffect(() => {
        //si existen filtros aplicados entonces los setea a la variable
        const filterName = `${tipoFiltro}_${viewField}`;
        const isAnyFilterSelected = queryObj.find(
            (filter) => filter.filterName === filterName
        );

        // console.log(isAnyFilterSelected);
        if (isAnyFilterSelected) {
            if("aplicado" in isAnyFilterSelected && isAnyFilterSelected.aplicado){
                setIsAplicado(true);
            }else{
                setIsAplicado(false);
            }
            // console.log("se encontro el filtro");
            // if ("aplicado" in isAnyFilterSelected) {
            //     console.log(
            //         "tiene la key aplicado ",
            //         isAnyFilterSelected.aplicado
            //     );
            //     if (isAnyFilterSelected.aplicado === null) {
            //         console.log("aplicado es null");
            //         console.log(isAnyFilterSelected.filterName);
            //         const action = {
            //             type: "addOrUpdateFilter",
            //             payload: {
            //                 filterName: isAnyFilterSelected.filterName,
            //                 filterType: isAnyFilterSelected.filterType,
            //                 filterField: isAnyFilterSelected.filterField,
            //                 [tipo === "single" ? "value" : "values"]:
            //                     tipo === "single"
            //                         ? isAnyFilterSelected.value
            //                         : isAnyFilterSelected.values,
            //                 displayvalue: isAnyFilterSelected.displayvalue,
            //                 operacion: isAnyFilterSelected.operacion,
            //                 aplicado: true,
            //             },
            //         };
            //         console.log(action);
            //         // dispatchFilterReducer(action);
            //         setIsAplicado(true);
            //     } else {
            //     }
            // }
        } else {
            setIsAplicado(null);
        }
        if (tipo === "multiple") {
            setValuesSelected(
                isAnyFilterSelected ? isAnyFilterSelected.displayvalue : []
            );
        } else {
            setValueSelected(
                isAnyFilterSelected ? isAnyFilterSelected.displayvalue : null
            );
        }
    }, [open])    

    //funcion para seleccionar un item en base al tipo (multiple o single) que es
    const selectValueFiltro = () => {
        const selection =
            (tipo === "single" && valueSelected !== null) ||
            (tipo === "multiple" && valuesSelected.length > 0);
            
        if(selection){
            const action = {
                type: "addOrUpdateFilter",
                payload: {
                    filterName: tipoFiltro + "_" + viewField,
                    filterType: tipoFiltro,
                    filterField: viewField,
                    [tipo === 'single' ? 'value' : 'values']: tipo === 'single' ? valueSelected.id : valuesSelected,
                    displayvalue: tipo === 'single' ? valueSelected[selectorView] : valuesSelected ,
                    operacion: operacion,
                    aplicado: false,
                },
            };
            dispatchFilterReducer(action);
            setOpen(!open);
            setIsAplicado(false)
        }else{
            setMessage("Debe seleccionar un item para continuar");
        }
    };

    //funcion para eliminar la seleccion hecha
    const deleteSelection = () => {
        if(tipo === 'single'){
            setValueSelected(null);
        }else{
            setValuesSelected([]);
        }
        if(isAplicado){
            setIsAplicado(false)
            const action = {
                type: "addOrUpdateFilter",
                payload: {
                    filterName: tipoFiltro + "_" + viewField,
                    filterType: tipoFiltro,
                    filterField: viewField,
                    operacion: operacion,
                    aplicado: null,
                },
            };
            dispatchFilterReducer(action);
        }else{
            setIsAplicado(null)
            const action = {
                type: "deleteFilter",
                payload: {
                    filterName: tipoFiltro + "_" + viewField,
                },
            };
            dispatchFilterReducer(action);
        }
    }

    //valida filtros que tiene que cumplir requisitos para que devuelva true o false
    function validateFilterCheck(tipo) {
        let filtro = false;

        if (tipo === 'single'){
            if(valueSelected && valueSelected != null || displayvalue != "" && aplicado){
                filtro = true;
            }else{
                filtro = false;
            }
        }else{
            if(valuesSelected && valuesSelected.length > 0 || displayvalue != "" && aplicado){
                filtro = true;
            }else{
                filtro = false;
            }
        }
        return filtro;
    }
    
    return (
        <div className="mt-4">
            <div className="inline-flex items-center justify-between w-full">
                <button
                    onClick={openFilterRequest}
                    className="inline-flex items-center text-sm font-medium text-blue-600 hover:underline"
                >
                    {validateFilterCheck(tipo)
                        ? tipo === 'single' ? `${titulo + ": " + valueSelected}` : `Selecciono: ${valuesSelected.length} ${titulo}(s)`
                        : title}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                        />
                    </svg>
                </button>
                {isAplicado !== null && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${
                            isAplicado ? "text-green-600" : "text-gray-400"
                        } h-6 w-6`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                        />
                    </svg>
                )}
            </div>
            <div>
                <Transition.Root show={open} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={setOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="md:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                                        {message != "" && (
                                            <div className="w-1/2 mx-auto">
                                                <div className="rounded-md bg-yellow-50 p-4">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-yellow-400"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-3">
                                                            <p className="text-sm font-medium text-yellow-800">
                                                                {message}
                                                            </p>
                                                        </div>
                                                        <div className="ml-auto pl-3">
                                                            <div className="-mx-1.5 -my-1.5">
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        setMessage(
                                                                            ""
                                                                        )
                                                                    }
                                                                    className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                                                                >
                                                                    <span className="sr-only">
                                                                        Dismiss
                                                                    </span>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-6 w-6 text-yellow-600"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="absolute right-0 top-0 pr-4 pt-10 sm:hidden">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                onClick={() => setOpen(false)}
                                            >
                                                <span className="sr-only">
                                                    Close
                                                </span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 w-full sm:ml-4 sm:mt-0 sm:text-left">
                                                <div className="inline-flex items-center w-full justify-between mb-5">
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-base font-semibold leading-6 text-gray-900"
                                                    >
                                                        {nombre}
                                                    </Dialog.Title>
                                                    <div className="relative mr-10 rounded-md shadow-sm justify-end">
                                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-400"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input
                                                            type="search"
                                                            value={busqueda}
                                                            onChange={(e) =>
                                                                setBusqueda(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="Búsqueda inteligente"
                                                        />
                                                    </div>
                                                </div>
                                                {formato == "selTree" ? (
                                                    <div></div>
                                                ) : (
                                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                            <div className="overflow-hidden md:rounded-lg">
                                                                {loading ? (
                                                                    <div className="md:h-40 h-56">
                                                                        <TripleMaze
                                                                            centered={
                                                                                true
                                                                            }
                                                                            height={
                                                                                "250px"
                                                                            }
                                                                            text={
                                                                                "Cargando..."
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="h-96 overflow-y-auto">
                                                                        <Table
                                                                            data={
                                                                                filterResult
                                                                            }
                                                                            column={
                                                                                allColumns
                                                                            }
                                                                            view={
                                                                                tipo
                                                                            }
                                                                            filtro={
                                                                                handleSelect
                                                                            }
                                                                            filtroSelect={
                                                                                tipo === 'single' ? valueSelected : valuesSelected
                                                                            }
                                                                            sortConfig={sortConfig}
                                                                            setSortConfig={setSortConfig}
                                                                            busqueda={busqueda}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                                onClick={selectValueFiltro}
                                            >
                                                Seleccionar
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
                {validateFilterCheck(tipo) && (
                    <button
                        className="text-xs text-red-300 hover:text-red-600 hover:underline"
                        onClick={deleteSelection}
                    >
                        Limpiar selección
                    </button>
                )}
            </div>
        </div>
    );
};
