import React from 'react';

export const PrecioFormateado = ({ precio, moneda }) => {
  const precioFormateado = precio.toLocaleString('es-AR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function existMoneda (moneda) {
    if(moneda && "Simbolo" in moneda){
      return moneda.Simbolo
    }else{
      return "";
    }
  }
  
  return (
      <p className='md:w-full inline-flex items-center md:flex md:justify-between md:space-x-0 space-x-1'>
          <span>{existMoneda(moneda)}</span>
          <span>{precioFormateado}</span>
      </p>
  );
  // <span className='w-full justify-between'></span>;
};
