export const useResourcesCache = () => {
    const findResourceCache = (resourceList, resourceName = "") => {
        return resourceList.indexOf(resourceName) > -1;
    }

    const findResourceOffset = (resourceOffset, resourceName = "") => {
        const offset = resourceOffset.find((item) => item.Resource === resourceName);
        // console.log(offset);
        if(offset){
            return offset.Offset;
        }else{
            return -1;
        }

    }

    return {
        findResourceCache,
        findResourceOffset,
    }
}
