import localforage from "localforage";

export const localForage = (resourceCache = []) => {
    // console.log(localforage);
    if(resourceCache.length > 0){
        
        var nameDb = window.location.hostname.split(".");
        const configDb = {
            driver: [
                localforage.WEBSQL,
                localforage.INDEXEDDB,
                localforage.LOCALSTORAGE,
            ],
        };
        
        const instanceDb = localforage.createInstance({
            configDb,
            storeName: nameDb[0],
        });

        resourceCache.forEach((element) => {
            instanceDb.setItem(element, []);
        });

        console.log(instanceDb);

        return instanceDb;
    }
}