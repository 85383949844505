import { Combobox } from "@headlessui/react";
import { useContext, useState, useEffect } from "react";
import FilterContext from "../../context/FilterContext";

export const FilterBySelectGeneric = ({
    displayvalue,
    source,
    sourceIdField,
    sourceDescField,
    tipoFiltro,
    operacion,
    title,
    viewField,
    inicial,
    filtervalue,
}) => {
    const { acciones, dispatchFilterReducer } = useContext(FilterContext);
    const [queryString, setQueryString] = useState("");
    const [selectedValue, setSelectedValue] = useState("");

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    // console.log(sourceIdField);

    const filteredSource =
        queryString === ""
            ? source
            : source.filter((item) => {
                  return item[sourceDescField]
                      .toLowerCase()
                      .includes(queryString.toLowerCase());
              });

    useEffect(() => {
        if (inicial) {
            const action = {
                type: "addOrUpdateFilter",
                payload: {
                    filterName: tipoFiltro + "_" + viewField,
                    filterType: tipoFiltro,
                    filterField: viewField,
                    value: filtervalue,
                    displayValue: displayvalue,
                    operacion: operacion,
                },
            };

            dispatchFilterReducer(action);
        } else if (selectedValue) {
            const action = {
                type: "addOrUpdateFilter",
                payload: {
                    filterName: tipoFiltro + "_" + viewField,
                    filterType: tipoFiltro,
                    filterField: viewField,
                    value: selectedValue[sourceIdField],
                    displayValue: displayvalue,
                    operacion: operacion,
                },
            };

            dispatchFilterReducer(action);
        }
    }, [selectedValue]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setQueryString(value);
    };

    const clickClient = (e) => {
        const optionSelected = source.find(
            (item) => item[sourceDescField] === e.target.innerText
        );
    };

    function deleteSelection() {
        setQueryString("");
        setSelectedValue(null);
        // console.log("bySelect_" + viewField);
        const action = {
            type: "deleteFilter",
            payload: { filterName: tipoFiltro + "_" + viewField },
        };

        dispatchFilterReducer(action);
    }

    return (
        <Combobox
            className="mt-4"
            as="div"
            value={selectedValue}
            onChange={setSelectedValue}
            // (e) => console.log(e.RazonSocial)
        >
            <Combobox.Label className="block text-sm font-medium text-gray-800">
                {title}
            </Combobox.Label>
            <div className="relative w-full">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:outline-none sm:text-sm"
                    onChange={handleInputChange}
                    // displayValue={(item) => (item ? item[sourceDescField] : "")}
                    value={displayvalue}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <svg viewBox="0 0 32 32" className="h-5 w-5 text-gray-400">
                        <path
                            fill="#888888"
                            d="m16 28l-7-7l1.41-1.41L16 25.17l5.59-5.58L23 21l-7 7zm0-24l7 7l-1.41 1.41L16 6.83l-5.59 5.58L9 11l7-7z"
                        ></path>
                    </svg>
                </Combobox.Button>

                {filteredSource.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-2xl border-b border-l border-r border-gray-300 focus:outline-none sm:text-sm">
                        {filteredSource.map((item, index) => (
                            <Combobox.Option
                                onClick={clickClient}
                                key={index}
                                value={item}
                                className={({ active }) =>
                                    classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                            ? "bg-indigo-600 text-white"
                                            : "text-gray-900 "
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <div className="flex">
                                            <span
                                                className={classNames(
                                                    "truncate",
                                                    selected && "font-semibold"
                                                )}
                                            >
                                                {item[sourceDescField]}
                                            </span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                                    active
                                                        ? "text-white"
                                                        : "text-indigo-600"
                                                )}
                                            >
                                                <svg
                                                    className="h-5 w-5"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M5 13l4 4L19 7"
                                                    />
                                                </svg>
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
            <button
                className="text-xs text-red-300 hover:text-red-600 hover:underline"
                onClick={deleteSelection}
            >
                Eliminar selección
            </button>
        </Combobox>
    );
};
