//custom hook
import { useSelectorGenerico } from "./useSelectorGenerico";

//components
import { Table, TableFormat } from "../../TableComponent";
import { Alert } from "../../Alerts";


export const SelectorGenerico = ({
    view = "grid",
    ruta,
    title,
    filter,
    id = [],
    extraArgument
}) => {
    const {
        data,
        loading,
        allColumns,
        messageAlert,
        sortConfig,
        applyChanges,
        saveChanges,
        setAllColumns,
        defaultSetting,
        setSortConfig,
        busqueda,
        acciones,
        setBusqueda,
        filterResult,
        paginationFinish,
    } = useSelectorGenerico(ruta, filter, id, extraArgument);

    return (
        <>
            <Alert />
            <div className="flex flex-col h-full px-6 md:py-4 w-full mt-5">
                <div className="md:inline-flex xs:flex-col items-center justify-between mb-5 w-full md:space-x-5 -mx-4 md:-mx-0 space-y-2">
                    <h1 className="text-2xl tracking-tight font-semibold text-gray-800 w-full">
                        {title}
                    </h1>
                    <div className="max-w-lg w-full lg:max-w-xs">
                        <label htmlFor="search" className="sr-only">
                            Busqueda
                        </label>
                        <div className="relative rounded-md shadow-sm justify-end">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </div>
                            <input
                                type="search"
                                value={busqueda}
                                onChange={(e) => setBusqueda(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Búsqueda inteligente"
                            />
                        </div>
                    </div>
                    {paginationFinish && !loading && (
                        <div className="w-full inline-flex items-center md:justify-end">
                            <p className="text-xs italic text-gray-400 mr-2">
                                Los items se están cargando
                            </p>
                            <svg
                                className="w-6 h-6 text-gray-300 animate-spin mr-3"
                                viewBox="0 0 64 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                    stroke="currentColor"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                    stroke="currentColor"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="text-gray-900"
                                ></path>
                            </svg>
                        </div>
                    )}
                    <div className="md:block hidden md:w-1/2 text-right">
                        <TableFormat
                            messageAlert={messageAlert}
                            allColumns={allColumns}
                            setAllColumns={setAllColumns}
                            ruta={ruta}
                            onApplyChanges={applyChanges}
                            onSaveChanges={saveChanges}
                            onDefaultWebForm={defaultSetting}
                        />
                    </div>
                </div>
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 max-h-full overflow-auto">
                        <div className="overflow-hidden md:rounded-lg">
                            {loading ? (
                                <div className="mt-52 flex-col h-full inline-flex items-center justify-center mx-auto space-y-5 w-full">
                                    <div className="-mt-52 w-20 h-20 rounded-full animate-spin border-x-8 border-solid border-green-600 border-t-transparent shadow-md"></div>
                                    <p className="text-center">
                                        Cargando . . .
                                    </p>
                                </div>
                            ) : filterResult ? (
                                <div className="h-96 overflow-y-auto">
                                    <Table
                                        data={filterResult}
                                        column={allColumns}
                                        view={view}
                                        filtroSelect={id}
                                        acciones={acciones}
                                        sortConfig={sortConfig}
                                        setSortConfig={setSortConfig}
                                        busqueda={busqueda}
                                        paginationFinish={paginationFinish}
                                    />
                                </div>
                            ) : (
                                <div className="w-full py-4 px-2 mt-20">
                                    <p className="text-gray-400 italic text-center">
                                        No hay datos para esta busqueda
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
