import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../../context/UserContext";
import AlertContext from "../../../../context/AlertContext";
import { generarSignature } from "../../../../hooks/useSignatureOperation";

export const ModalOptionsRowOpi = ({
    action,
    actionText,
    item,
    refresh,
    id,
    requestEndpoint = "",
    requestAction,
    handlePopover,
}) => {
    const cancelButtonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [historialEstados, setHistorialEstados] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [newValue, setNewValue] = useState(null);

    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const { url, headers, user, workstationActive } = useContext(UserContext);

    // console.log(refresh);
    const initialRequest = () => {
        switch (requestAction) {
            case "previewPDF":
                verPDF();
                break;

            case "cambio_estado":
                handleRequestData(item.EstadoKey);
                break;

            default:
                setOpen(true)
                break;
        }
    };

    const handleRequestData = async (auxParam) => {
        setOpen(true);
        setLoading(true);
        await axios
            .post(url + requestEndpoint, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
            })
            .then(function (res) {
                console.log(res);
                setRequestData(res.data);
                setLoading(false);
                if (auxParam !== null || auxParam !== undefined) {
                    setNewValue(auxParam);
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    //funcion para obtener el PDF en una ventana nueva
    const verPDF = async () => {
        await axios
            .post(
                url + requestEndpoint,
                {
                    Usuario: user.UsuarioKey,
                    Workstation: workstationActive.WorkstationKey,
                    Llave: generarSignature(id),
                },
                { responseType: "arraybuffer" }
            )
            .then(function (response) {
                // console.log(response);
                const file = new Blob([response.data], {
                    type: "application/pdf",
                });
                // Construir la URL del Blob
                const fileURL = URL.createObjectURL(file);
                // console.log(fileURL);
                // Abrir el PDF en una nueva ventana o pestaña
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                handlePopover();
            })
            .catch(function (error) {
                console.log('error de pdf modal options row opi');
                console.log(error.response);
            });
    };

     const handleChangeRequest = async () => {
         console.log(url + action + constructUrl(newValue));
         await axios
             .post(url + action + constructUrl(newValue), {
                 Workstation: workstationActive.WorkstationKey,
                 Usuario: user.UsuarioKey,
             })
             .then(function (res) {
                 console.log(res);
                //  console.log(refresh());
                 if (res.status === 200) {
                     refresh();
                     setOpen(false);
                     setResponseText("Se modificó la orden exitósamente");
                     setStatusCode(200);
                 }
             })
             .catch(function (error) {
                 console.log(error.response);
                 if (error.response != undefined) {
                     setOpen(false);
                     if (error.response.status === 404) {
                         setResponseText(
                             "Error de petición. Consulte con administración"
                         );
                     } else {
                         setResponseText(error.response.data.Motivo);
                     }
                     setStatusCode(error.response.status);
                 } else {
                     setOpen(false);
                     setResponseText(
                         "Hubo un error crítico. Consulte con administración"
                     );
                 }
                 handlePopover();
             });
     };

     const constructUrl = (param) => {
         switch (requestAction) {
            case "cambio_estado":
                return `${param}/${id}`;
        
            case "retorno_estado":
                return `${id}/1`
            default:
                return undefined;
         }
     };


    return (
        <>
            <button
                className="flex items-center font-medium text-left px-4 py-2 text-sm text-gray-700 hover:underline"
                onClick={initialRequest}
            >
                {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-auto sm:p-6">
                                    {(() => {
                                        switch (requestAction) {
                                            case "cambio_estado":
                                                return (
                                                    <div
                                                        className={`${
                                                            loading
                                                                ? "opacity-50"
                                                                : "opacity-100"
                                                        }`}
                                                    >
                                                        <div>
                                                            <label className="text-base font-medium text-gray-900">
                                                                Estados
                                                                disponibles
                                                            </label>
                                                            <p className="text-sm leading-5 text-gray-500">
                                                                Elija el estado
                                                                que quiera
                                                                cambiar la
                                                                operación
                                                            </p>
                                                            <fieldset className="mt-4">
                                                                <legend className="sr-only">
                                                                    State change
                                                                    method
                                                                </legend>
                                                                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                    {requestData.map(
                                                                        (
                                                                            est,
                                                                            estIdx
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    estIdx
                                                                                }
                                                                                className="flex items-center"
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    checked={
                                                                                        newValue ==
                                                                                        est.id
                                                                                    }
                                                                                    value={
                                                                                        est.id
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setNewValue(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                />
                                                                                <label
                                                                                    htmlFor={
                                                                                        est.id
                                                                                    }
                                                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                                                >
                                                                                    {
                                                                                        est.Nombre
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                );
                                            case "retorno_estado":
                                                return (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-red-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-base font-semibold leading-6 text-gray-900"
                                                            >
                                                                Retornar un estado anterior
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">
                                                                    ¿Está seguro que quiere volver un estado anterior de la operación?
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })()}

                                    <div
                                        className={`mt-5 sm:mt-4 sm:flex sm:flex-row-reverse ${
                                            loading
                                                ? "opacity-50"
                                                : "opacity-100"
                                        }`}
                                    >
                                        <button
                                            type="button"
                                            className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-blue-600 hover:bg-blue-800 ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={handleChangeRequest}
                                        >
                                            Aceptar
                                        </button>
                                        <button
                                            type="button"
                                            className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={() => setOpen(!open)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
