import { useState, useContext, useLayoutEffect, useRef, useEffect } from "react";
import ModalContext from "../../context/ModalContext";
import ReactPaginate from "react-paginate";
import './index.css'

import { ModalOptionsHeaderOcc } from "../Modales/Options";
import { TableRow } from "./TableRow";

export const Table = ({
    data,
    column = [],
    view,
    ruta,
    modalEdit,
    modalView,
    refresh,
    acciones = [],
    filtro = null,
    filtroSelect,
    sortConfig,
    setSortConfig,
    busqueda = "",
    paginationFinish
}) => {
    const checkbox = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPorPagina] = useState(50);
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [optionsHeader, setOptionsHeader] = useState(false);
    const tableElement = useRef(null);

    const [selectedData, setSelectedData] = useState([]);
    const { select, setSelect } = useContext(ModalContext);

    const paginasCount = Math.ceil(data.length / itemsPorPagina);

    // const colReduced = column.slice().sort((a, b) => a.Orden - b.Orden);
    var reduced = column.reduce(function (filtered, columnItem) {
        if (columnItem.Visible === true) {
            var columnasDefault = {
                FormFieldName: columnItem.FormFieldName,
                FormFieldTitle: columnItem.FormFieldTitle,
                Orden: columnItem.Orden,
            };
            filtered.push(columnasDefault);
        }
        return filtered;
    }, []);

    const handlePageClick = async (data) => {
        var currentPage = data.selected + 1;
        setCurrentPage(currentPage);
        if(tableElement.current){
            tableElement.current.scrollIntoView({behavior: "smooth", block: "start"})
        }
    };

    const indexOfLastItem = currentPage * itemsPorPagina;
    const indexOfFirstItem = indexOfLastItem - itemsPorPagina;
    const sortedData = data.slice().sort((a, b) => {
        const valueA = a[sortConfig.key];
        const valueB = b[sortConfig.key];

        // Asegúrate de que los valores sean comparables
        if (typeof valueA === "string" && typeof valueB === "string") {
            return sortConfig.direction === "asc"
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
        } else {
            return sortConfig.direction === "asc"
                ? valueA - valueB
                : valueB - valueA;
        }
    });
    const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const handleRadioButtonOption = (e) => {
        // console.log(e.target.value);
        if (filtro != null) {
            let objSelected = JSON.parse(e.target.id);
            filtro(objSelected);
        } else {
            let parseSelect = parseInt(e.target.value);
            setSelect(parseSelect);
        }
    };

    const handleCheckboxOption = (e) => {
        if (filtro != null) {
            filtro(e.id);
        } else {
            setSelect(e.id);
        }
    };

    useLayoutEffect(() => {
        const isIndeterminate =
            selectedData.length > 0 && selectedData.length < currentData.length;
        setChecked(selectedData.length === currentData.length);
        setIndeterminate(isIndeterminate);
        checkbox.current.indeterminate = isIndeterminate;
    }, [selectedData]);

    function toggleAll() {
        setSelectedData(checked || indeterminate ? [] : currentData);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const toggleOptionsHeader = () => {
        setOptionsHeader(!optionsHeader);
    };

    const handleDirectionSorteable = (columnName) => {
        const newDirection = sortConfig.key === columnName ? (sortConfig.direction === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortConfig({ key: columnName, direction: newDirection });
    };

    useEffect(() => {
        if(checked){
            setChecked(false)
        }
    }, [checked])

    useEffect(() => {
        if (busqueda !== "") {
            setCurrentPage(1);
        }
    }, [busqueda]);

    return (
        <>
            {/* tabla */}
            <table ref={tableElement} className="min-w-full divide-y divide-gray-300 table-auto">
                <thead className="bg-gray-300 sticky top-0">
                    <tr
                        className={`${
                            currentData.length === 0 ? "hidden" : "relative"
                        }`}
                    >
                        <th
                            scope="col"
                            className={`w-12 px-6 sm:w-16 sm:px-8 ${
                                view === "single" || view === "multiple" ? "hidden" : "relative"
                            }`}
                        >
                            <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                            />
                            {selectedData.length > 0 && (
                                <div className="tooltip absolute top-0.5 left-4 flex h-4 items-center bg-gray-50 ">
                                    <button onClick={toggleOptionsHeader}>
                                        <span className="sr-only">
                                            Opciones
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-indigo-600 hover:text-indigo-900"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                            />
                                        </svg>
                                    </button>
                                    {optionsHeader && (
                                        <div
                                            className={`absolute left-0 z-10 w-52 origin-top-right rounded-md bg-gray-100 border shadow-lg`}
                                        >
                                            {
                                                {
                                                    ordencompracliente: (
                                                        <ModalOptionsHeaderOcc
                                                            action="del_multiple_occ"
                                                            actionText="Eliminar seleccionados "
                                                            items={selectedData}
                                                            handleAction={
                                                                setOptionsHeader
                                                            }
                                                        />
                                                    ),
                                                    operacionPresupuesto: <></>,
                                                }[modalEdit]
                                            }
                                        </div>
                                    )}
                                </div>
                            )}
                        </th>
                        {reduced.map((item, index) => (
                            <TableHeader
                                key={index}
                                item={item}
                                sortConfig={sortConfig}
                                handleDirectionSorteable={
                                    handleDirectionSorteable
                                }
                            />
                        ))}
                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">#</span>
                        </th>
                    </tr>
                </thead>
                <tbody
                    className={`bg-white ${
                        currentData.length === 0
                            ? undefined
                            : "divide-y divide-gray-200"
                    }`}
                >
                    {currentData.length !== 0 ? (
                        currentData.map((item, itemIdx) => (
                            <TableRow
                                key={itemIdx}
                                index={itemIdx}
                                selectedData={selectedData}
                                setSelectedData={setSelectedData}
                                item={item}
                                view={view}
                                ruta={ruta}
                                modalView={modalView}
                                refresh={refresh}
                                modalEdit={modalEdit}
                                select={select}
                                handleCheckboxOption={handleCheckboxOption}
                                handleRadioButtonOption={
                                    handleRadioButtonOption
                                }
                                reduced={reduced}
                                acciones={acciones}
                                filtroSelect={filtroSelect}
                            />
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={8}
                                className="py-16 italic text-sm text-gray-400 text-center"
                            >
                                No hay ningún dato para mostar
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {/* paginacion */}
            <div
                className={`${
                    currentData.length === 0 || paginasCount === 1
                        ? "hidden"
                        : "relative"
                } ${paginationFinish ? "custom-class" : ""}`}
            >
                <ReactPaginate
                    onPageChange={handlePageClick}
                    previousLabel={currentPage !== 1 ? "Anterior" : ""}
                    nextLabel={currentPage === paginasCount ? "" : "Siguiente"}
                    breakLabel=". . ."
                    pageCount={paginasCount}
                    forcePage={
                        currentPage === 1 && data.length === 0
                            ? -1
                            : currentPage - 1
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    containerClassName={
                        "border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
                    }
                    pageClassName={"hidden md:-mt-px md:flex"}
                    pageLinkClassName={
                        "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium no-underline"
                    }
                    previousClassName={"-mt-px w-0 flex-1 flex"}
                    nextClassName={"-mt-px w-0 flex-1 flex justify-end"}
                    previousLinkClassName={
                        "border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 no-underline"
                    }
                    nextLinkClassName={
                        "border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 no-underline"
                    }
                    activeLinkClassName={
                        "border-indigo-500 text-indigo-600 text-xl"
                    }
                    breakClassName={
                        "border-transparent text-gray-500 border-t-2 pt-4 px-4 md:inline-flex items-center text-sm font-medium hidden"
                    }
                />
            </div>
        </>
    );
};

const TableHeader = ({ item, sortConfig, handleDirectionSorteable }) => (
    <th className={`${item.Orden !== 1 ? "hidden md:table-cell" : "text-left"} w-full md:w-auto px-3 py-2.5 text-sm text-gray-900`}>
        <span className="md:hidden">Datos</span>
        <div className="md:inline-flex md:items-center hidden">
            <span className="hover:underline hover:cursor-pointer" onClick={() => handleDirectionSorteable(item.FormFieldName)}>{item.FormFieldTitle}</span>
            {sortConfig.key === item.FormFieldName && (
                <button onClick={() => handleDirectionSorteable(item.FormFieldName)} >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-4 w-4 ml-3 ${sortConfig.direction === 'desc' ? 'rotate-180': ''}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 15l7-7 7 7"
                        />
                    </svg>
                </button>
            )}
        </div>
    </th>
);