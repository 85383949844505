import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import localforage from 'localforage';

export const useLogout = () => {
    const { setUser, setWorkstationActive, setVersionDb, setResourceCache, resourceCache } = useContext(UserContext);
    const navigate = useNavigate();

    const logout = () => {
        localforage.dropInstance({name:'localforage'});
        setUser(null);
        setWorkstationActive(null);
        setVersionDb(null);
        setResourceCache(null);
        localStorage.removeItem("user");
        localStorage.removeItem("workstation");
        localStorage.removeItem("version");
        localStorage.removeItem("resourcePagination");
        JSON.parse(localStorage.getItem('resourceCache')).map((item) => {
            localStorage.removeItem(item);
        });        
        localStorage.removeItem("resourceCache");
        localStorage.removeItem("logo");
        
        navigate("/", {
            replace: true,
        });
    }

    return {logout}
}