import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../../context/UserContext";
import AlertContext from "../../../../context/AlertContext";

export const ModalOptionsRowOcc = ({
    action,
    actionText,
    item,
    handleAction,
    refresh,
}) => {
    const cancelButtonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [historialEstados, setHistorialEstados] = useState([]);
    const [estados, setEstados] = useState([]);
    const [newEstado, setNewEstado] = useState(item.EstadoKey);

    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const { url, headers, user } = useContext(UserContext);

    const requestAction = () => {
        setOpen(true);
        // handleAction(false);
        switch (action) {
            case "estadooperacion/":
                var urlParam = url + action + item.id;
                return request(urlParam);

            case "estado/":
                var urlParam = url + action + "5/" + user.UsuarioKey;
                return request(urlParam);

            case "previewPDF":
                return null;
            default:
                break;
        }
        request();
    };

    const request = async (urlParam) => {
        await axios
            .get(urlParam, { headers: headers })
            .then(function (response) {
                if (response.status === 200) {
                    switch (action) {
                        case "estadooperacion/":
                            setHistorialEstados(response.data);
                        case "estado/":
                            setEstados(response.data);

                        default:
                            break;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleRequestAction = () => {
        switch (action) {
            case "estado/":
                var executeUrl =
                    url +
                    "ordencompracliente/changestate/" +
                    newEstado +
                    "/" +
                    item.id;
                // console.log(executeUrl);
                return formRequest(executeUrl, "put");
            default:
                break;
        }
    };

    const formRequest = async (executeUrl, httpReq) => {
        setLoading(true);
        switch (httpReq) {
            case "put":
                await axios
                    .put(executeUrl, item, { headers: headers })
                    .then(function (response) {
                        // console.log(response);
                        if (response.status === 200) {
                            setStatusCode(200);
                            setResponseText(
                                "Se modificó la operación Nº " + item.Numero
                            );
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {
                        setStatusCode(error.response.status);
                        if (error.response.data && "Motivo" in error.response.data) {
                            setResponseText(error.response.data["Motivo"]);
                            setTimeout(() => {
                                setResponseText("");
                            }, 3000);
                        }
                        setLoading(false);
                    });
                break;
            case "get":
                await axios
                    .get(executeUrl, { headers: headers })
                    .then(function (response) {
                        if (response.status === 200) {
                            setStatusCode(200);
                            setResponseText(
                                "Se modificó la operación Nº " + item.Numero
                            );
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {
                        setStatusCode(error.response.status);
                        if (error.response.data && "Motivo" in error.response.data) {
                            setResponseText(error.response.data["Motivo"]);
                            setTimeout(() => {
                                setResponseText("");
                            }, 3000);
                        }
                        setLoading(false);
                    });

            default:
                break;
        }
        refresh();
        cancelButton();
    };

    const cancelButton = () => {
        setOpen(false);
        handleAction(false);
    };

    return (
        <>
            <button
                className="w-full font-medium block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                onClick={requestAction}
            >
                {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-auto sm:p-6">
                                    {(() => {
                                        switch (action) {
                                            case "estadooperacion/":
                                                return (
                                                    <div className="flow-root">
                                                        <ul role="list">
                                                            {historialEstados.map(
                                                                (
                                                                    event,
                                                                    eventIdx
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            eventIdx
                                                                        }
                                                                    >
                                                                        <div className="relative pb-8">
                                                                            {eventIdx !==
                                                                            historialEstados.length -
                                                                                1 ? (
                                                                                <span
                                                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : null}
                                                                            <div className="relative flex space-x-3">
                                                                                <div>
                                                                                    <span
                                                                                        className={`h-8 w-8 rounded-full flex items-center justify-center ring-1 ring-white ${
                                                                                            event.Ultimo ===
                                                                                            1
                                                                                                ? "bg-green-600"
                                                                                                : "bg-gray-300"
                                                                                        } `}
                                                                                    ></span>
                                                                                </div>
                                                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                                                    <div>
                                                                                        <p className="text-sm text-gray-500">
                                                                                            {
                                                                                                event.Estado
                                                                                            }{" "}
                                                                                            por{" "}
                                                                                            <span className="text-gray-700 font-semibold">
                                                                                                {
                                                                                                    event.Usuario
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                                                        {Moment(
                                                                                            event.FechaEstado
                                                                                        ).format(
                                                                                            "DD-MMM-YYYY"
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                );
                                            case "estado/":
                                                return (
                                                    <div
                                                        className={`${
                                                            loading
                                                                ? "opacity-50"
                                                                : "opacity-100"
                                                        }`}
                                                    >
                                                        <div>
                                                            <label className="text-base font-medium text-gray-900">
                                                                Estados
                                                                disponibles
                                                            </label>
                                                            <p className="text-sm leading-5 text-gray-500">
                                                                Elija el estado
                                                                que quiera
                                                                cambiar la
                                                                operación
                                                            </p>
                                                            <fieldset className="mt-4">
                                                                <legend className="sr-only">
                                                                    State change
                                                                    method
                                                                </legend>
                                                                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                    {estados.map(
                                                                        (
                                                                            est,
                                                                            estIdx
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    estIdx
                                                                                }
                                                                                className="flex items-center"
                                                                            >
                                                                                <input
                                                                                    id={
                                                                                        est.id
                                                                                    }
                                                                                    type="radio"
                                                                                    checked={
                                                                                        newEstado ==
                                                                                        est.id
                                                                                    }
                                                                                    value={
                                                                                        est.id
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setNewEstado(
                                                                                            e
                                                                                                .target
                                                                                                .id
                                                                                        )
                                                                                    }
                                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                />
                                                                                <label
                                                                                    htmlFor={
                                                                                        est.id
                                                                                    }
                                                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                                                >
                                                                                    {
                                                                                        est.Nombre
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })()}

                                    <div
                                        className={`mt-5 sm:mt-4 sm:flex sm:flex-row-reverse ${
                                            loading
                                                ? "opacity-50"
                                                : "opacity-100"
                                        }`}
                                    >
                                        {
                                            {
                                                "estado/": (
                                                    <button
                                                        type="button"
                                                        className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-blue-600 hover:bg-blue-800 ${
                                                            loading
                                                                ? "cursor-not-allowed"
                                                                : "cursor-pointer"
                                                        }`}
                                                        onClick={
                                                            handleRequestAction
                                                        }
                                                    >
                                                        Aceptar
                                                    </button>
                                                ),
                                            }[action]
                                        }
                                        <button
                                            type="button"
                                            className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={cancelButton}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
