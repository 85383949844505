import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { useActions } from "../../permissions/useActions";
import UserContext from "../../context/UserContext";

const Item = ({ to, svg, text, openSidebar, children, action }) => {
    const { user } = useContext(UserContext);
    const { isAllowed } = useActions(user.Acciones);
    return (
        <>
            {isAllowed(action) && (
                <div className={`pt-2 md:group`}>
                    {!children ? (
                        <NavLink
                            to={to}
                            className={({ isActive }) =>
                                isActive
                                    ? "group flex items-center px-2 py-2 text-sm font-medium border-l-2 border-white bg-gray-900 text-white no-underline"
                                    : "group flex items-center px-2 py-2 text-sm font-medium md:text-gray-300 text-gray-800 md:hover:bg-gray-700 md:hover:text-white no-underline"
                            }
                        >
                            <div className="ml-1">{svg}</div>
                            {openSidebar ? (
                                <span className="md:absolute left-16 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                    {text}
                                </span>
                            ) : (
                                <p className="-my-1">{text}</p>
                            )}
                        </NavLink>
                    ) : (
                        <Disclosure>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        className={`w-full group flex items-center px-2 py-2 text-sm font-medium no-underline ${
                                            open
                                                ? "text-gray-800"
                                                : "text-gray-800"
                                        } md:text-gray-700 text-gray-300 hover:bg-gray-700 md:hover:text-white`}
                                    >
                                        <div
                                            className={`ml-1 md:text-gray-300 ${
                                                open
                                                    ? "text-white"
                                                    : "text-gray-800"
                                            }`}
                                        >
                                            {svg}
                                        </div>
                                        {openSidebar ? (
                                            <span className="md:absolute left-16 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                                {text}
                                            </span>
                                        ) : (
                                            <>
                                                <p
                                                    className={`md:text-gray-300 ${
                                                        open
                                                            ? "text-white"
                                                            : "text-gray-800"
                                                    }`}
                                                >
                                                    {text}
                                                </p>
                                                <svg
                                                    className={`ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-200 ease-in-out md:group-hover:text-gray-400 ${
                                                        open
                                                            ? " rotate-90 transform duration-200 ease-in-out"
                                                            : "md:text-gray-300 text-gray-800"
                                                    }`}
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        d="M6 6L14 10L6 14V6Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                        className={`px-4 pt-4 pb-2 text-sm text-gray-500 ${
                                            openSidebar ? "-mx-2" : ""
                                        }`}
                                    >
                                        {children.map(
                                            (subitem) =>
                                                isAllowed(subitem.action) && (
                                                    <NavLink
                                                        key={subitem.to}
                                                        to={subitem.to}
                                                        className={({
                                                            isActive,
                                                        }) =>
                                                            isActive
                                                                ? "group flex items-center px-2 py-2 text-sm font-medium border-l-2 border-white bg-gray-900 text-white no-underline"
                                                                : "group flex items-center px-2 py-2 text-sm font-medium text-gray-700 md:text-gray-300 md:hover:bg-gray-700 md:hover:text-white no-underline"
                                                        }
                                                    >
                                                        <div className="ml-1">
                                                            {subitem.svg}
                                                        </div>
                                                        {openSidebar ? (
                                                            <span className="md:absolute left-16 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                                                {subitem.text}
                                                            </span>
                                                        ) : (
                                                            <p className="-my-1">
                                                                {subitem.text}
                                                            </p>
                                                        )}
                                                    </NavLink>
                                                )
                                        )}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    )}
                </div>
            )}
        </>
    );
};

export default Item;
