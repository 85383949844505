import { useContext, useEffect, useState } from "react";
import Moment from "moment";
import UserContext from "../../context/UserContext";
import { useActions } from "../../permissions/useActions";

//modals
import {
    ViewModalOrdenCompra,
    ViewModalVenta,
    ViewModalParteOperativo,
    ViewModalPresupuestoCliente,
    ViewModalOrdenPedidoInternaSectorizada,
    ViewModalOrdenPedidoInternaDeposito,
} from "../Modales/View";
import {
    EditModalOrdenCompra,
    EditModalVenta,
    EditModalParteOperativo,
    EditModalPresupuestoCliente,
    EditModalOrdenPedidoInternaDeposito,
    EditModalOrdenPedidoInternaSectorizada
} from "../Modales/Edit";
import ModalDelete from "../Modales/Delete/ModalDelete";
import { ModalOptionsRowOcc, ModalOptionsRowOpi } from "../Modales/Options";

//utils
import { generarSignature } from "../../hooks/useSignatureOperation";
import { PrecioFormateado } from "../ElementsUi/PriceFormat";

//ui
import { Popover } from "@headlessui/react";
import { Alert } from "../Alerts";

export const TableRow = ({
    index,
    item,
    reduced,
    selectedData,
    setSelectedData,
    view,
    ruta,
    modalView,
    refresh,
    modalEdit,
    select,
    handleCheckboxOption,
    handleRadioButtonOption,
    acciones = [],
    filtroSelect = null,
}) => {
    const { isAllowed, parseRoute } = useActions(acciones);

    // const verPDF = (ruta, id) => {
    //     axios
    //         .post(
    //             url + ruta + "/print_rave/" + id,
    //             {
    //                 Usuario: user.UsuarioKey,
    //                 Workstation: workstationActive.WorkstationKey,
    //                 Llave: generarSignature(id),
    //             },
    //             { responseType: "arraybuffer" }
    //         )
    //         .then(function (response) {
    //             console.log(response);
    //             const file = new Blob([response.data], {
    //                 type: "application/pdf",
    //             });
    //             // Construir la URL del Blob
    //             const fileURL = URL.createObjectURL(file);
    //             console.log(fileURL);
    //             // Abrir el PDF en una nueva ventana o pestaña
    //             const pdfWindow = window.open();
    //             pdfWindow.location.href = fileURL;

    //             setOptionsRow(!optionsRow);
    //         })
    //         .catch(function (error) {
    //             console.log(error.response);
    //         });
    // };

    const verifyChecked = () => {
        let checked = false;
        // console.log(select == item.id);
        if (select == item.id) {
            checked = true;
        }
        if (filtroSelect != null) {
            if (
                (view === "single" && filtroSelect == item.id) ||
                filtroSelect.id == item.id
            ) {
                checked = true;
            }
            if (view === "multiple" && filtroSelect.includes(item.id)) {
                checked = true;
            }
        }
        // console.log(select);
        return checked;
    };

    return (
        <tr
            key={item.id}
            className={`flex-col md:none ${
                index % 2 === 0 ? undefined : "bg-gray-200"
            }`}
        >
            <td
                className={`w-12 px-6 sm:w-16 sm:px-8 ${
                    view === "single" || view === "multiple"
                        ? "hidden"
                        : "relative"
                }`}
            >
                {selectedData.includes(item) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                )}
                <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    value={item.id}
                    checked={selectedData.includes(item)}
                    onChange={(e) =>
                        setSelectedData(
                            e.target.checked
                                ? [...selectedData, item]
                                : selectedData.filter((p) => p !== item)
                        )
                    }
                />
            </td>
            {reduced.map((columnItem, index) => {
                if (columnItem.FormFieldName.includes(".")) {
                    const itemSplit = columnItem.FormFieldName.split(".");
                    return (
                        // <tr >
                        <td
                            key={index}
                            className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 truncate"
                        >
                            {item[itemSplit[0]][itemSplit[1]]}
                        </td>
                        // </tr>
                    );
                }
                return (
                    <td
                        key={index}
                        className="block md:table-cell font-normal pl-3 text-sm text-gray-700 py-0.5"
                    >
                        <dl className="md:hidden">
                            <dd className="text-xs">
                                <span className="font-xs font-bold">
                                    {columnItem.FormFieldTitle}:{" "}
                                </span>
                                {Moment(
                                    item[`${columnItem.FormFieldName}`],
                                    "YYYY-MM-DD HH:mm:ss",
                                    true
                                ).isValid() ? (
                                    Moment(
                                        item[`${columnItem.FormFieldName}`]
                                    ).format("DD/MM/YYYY")
                                ) : typeof item[
                                        `${columnItem.FormFieldName}`
                                    ] === "number" ? (
                                    <PrecioFormateado
                                        moneda={item}
                                        precio={
                                            item[
                                                `${columnItem.FormFieldName}`
                                            ]
                                        }
                                    />
                                ) : (
                                    item[`${columnItem.FormFieldName}`]
                                )}
                            </dd>
                        </dl>
                        <span
                            className={`md:block hidden ${
                                typeof item[
                                    `${columnItem.FormFieldName}`
                                ] === "number"
                                    ? "text-right"
                                    : ""
                            }`}
                        >
                            {Moment(
                                item[`${columnItem.FormFieldName}`],
                                "YYYY-MM-DD HH:mm:ss",
                                true
                            ).isValid() ? (
                                Moment(
                                    item[`${columnItem.FormFieldName}`]
                                ).format("DD/MM/YYYY")
                            ) : typeof item[
                                    `${columnItem.FormFieldName}`
                                ] === "number" ? (
                                <PrecioFormateado
                                    moneda={item}
                                    precio={
                                        item[`${columnItem.FormFieldName}`]
                                    }
                                />
                            ) : (
                                item[`${columnItem.FormFieldName}`]
                            )}
                        </span>
                    </td>
                );
            })}
            {(() => {
                if (view === "grid") {
                    return (
                        <td className="font-medium md:inline-flex md:items-center md:justify-end md:pr-5 py-1 mt-2 relative md:space-x-3 text-sm w-full whitespace-nowrap">
                            <div className="inline-flex md:items-center md:justify-end w-full space-x-2 pr-2 md:pr-0">
                                {
                                    {
                                        ordencompracliente: (
                                            <ViewModalOrdenCompra
                                                id={item.id}
                                                ruta={ruta}
                                                tipo={"cliente"}
                                            />
                                        ),
                                        ordencompraproveedor: (
                                            <ViewModalOrdenCompra
                                                id={item.id}
                                                ruta={ruta}
                                                tipo={"proveedor"}
                                            />
                                        ),
                                        venta: (
                                            <ViewModalVenta
                                                id={item.id}
                                                ruta={ruta}
                                            />
                                        ),
                                        parteoperativo: (
                                            <ViewModalParteOperativo
                                                id={item.id}
                                                ruta={ruta}
                                            />
                                        ),
                                        presupuestocliente: (
                                            <ViewModalPresupuestoCliente
                                                id={item.id}
                                                ruta={ruta}
                                            />
                                        ),
                                        ordenpedidointernasectorizada: (
                                            <ViewModalOrdenPedidoInternaSectorizada
                                                id={item.id}
                                                ruta={ruta}
                                                acciones={acciones}
                                            />
                                        ),
                                        ordenpedidointernadeposito: (
                                            <ViewModalOrdenPedidoInternaDeposito
                                                id={item.id}
                                                ruta={ruta}
                                                acciones={acciones}
                                            />
                                        ),
                                    }[modalView]
                                }
                                {
                                    {
                                        ordencompracliente: isAllowed(
                                            parseRoute(ruta) + "MODIFICAR"
                                        ) && (
                                            <EditModalOrdenCompra
                                                id={item.id}
                                                ruta={ruta}
                                                refresh={refresh}
                                                acciones={acciones}
                                                tipo={"cliente"}
                                            />
                                        ),
                                        ordencompraproveedor: isAllowed(
                                            parseRoute(ruta) + "MODIFICAR"
                                        ) && (
                                            <EditModalOrdenCompra
                                                id={item.id}
                                                ruta={ruta}
                                                refresh={refresh}
                                                acciones={acciones}
                                                tipo={"proveedor"}
                                            />
                                        ),
                                        venta: isAllowed(
                                            parseRoute(ruta) + "MODIFICAR"
                                        ) && (
                                            <EditModalVenta
                                                id={item.id}
                                                ruta={ruta}
                                                refresh={refresh}
                                            />
                                        ),
                                        parteoperativo: isAllowed(
                                            parseRoute(ruta) + "MODIFICAR"
                                        ) && (
                                            <EditModalParteOperativo
                                                id={item.id}
                                                ruta={ruta}
                                                refresh={refresh}
                                            />
                                        ),
                                        presupuestocliente: (
                                            /*isAllowed(
                                            parseRoute(ruta) + "MODIFICAR"
                                        ) && */ <EditModalPresupuestoCliente
                                                id={item.id}
                                                ruta={ruta}
                                                refresh={refresh}
                                                acciones={acciones}
                                            />
                                        ),
                                        ordenpedidointernasectorizada:
                                            isAllowed(
                                                parseRoute(ruta) +
                                                    "MODIFICAR"
                                            ) && (
                                                <EditModalOrdenPedidoInternaSectorizada
                                                    id={item.id}
                                                    ruta={ruta}
                                                    refresh={refresh}
                                                    acciones={acciones}
                                                />
                                            ),
                                        ordenpedidointernadeposito:
                                            isAllowed(
                                                parseRoute(ruta) +
                                                    "MODIFICAR"
                                            ) && (
                                                <EditModalOrdenPedidoInternaDeposito
                                                    id={item.id}
                                                    ruta={ruta}
                                                    refresh={refresh}
                                                    acciones={acciones}
                                                />
                                            ),
                                    }[modalEdit]
                                }
                                {(() => {
                                    switch (ruta) {
                                        case "ordencompracliente":
                                        case "venta":
                                        case "parteoperativo":
                                        case "ordenpedidointerna":
                                        case "presupuestocliente":
                                            return (
                                                isAllowed(
                                                    parseRoute(ruta) +
                                                        "ELIMINAR"
                                                ) && (
                                                    <ModalDelete
                                                        obj={item}
                                                        id={item.id}
                                                        ruta={ruta}
                                                        refresh={refresh}
                                                    />
                                                )
                                            );
                                        default:
                                            return null;
                                    }
                                })()}
                                {(() => {
                                    switch (ruta) {
                                        case "ordencompracliente":
                                        case "venta":
                                        case "ordenpedidointerna":
                                        case "presupuestocliente":
                                        case "parteoperativo":
                                            return (
                                                <div>
                                                    <Popover className="relative">
                                                        <Popover.Button className="tooltip">
                                                            <span className="tooltiptext">
                                                                Opciones
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-5 w-5 text-indigo-600 hover:text-indigo-900"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                                                />
                                                            </svg>
                                                        </Popover.Button>
                                                        <Popover.Panel className="absolute right-0 z-10 -mt-1.5 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none">
                                                            {({ close }) => (
                                                                <div className="p-1">
                                                                    {
                                                                        {
                                                                            ordencompracliente:
                                                                                (
                                                                                    <>
                                                                                        <ModalOptionsRowOcc
                                                                                            action="estadooperacion/"
                                                                                            actionText="Historial estados"
                                                                                            item={
                                                                                                item
                                                                                            }
                                                                                            handleAction={
                                                                                                close
                                                                                            }
                                                                                            refresh={
                                                                                                refresh
                                                                                            }
                                                                                        />
                                                                                        <ModalOptionsRowOcc
                                                                                            action="estado/"
                                                                                            actionText="Cambiar estado"
                                                                                            item={
                                                                                                item
                                                                                            }
                                                                                            handleAction={
                                                                                                close
                                                                                            }
                                                                                            refresh={
                                                                                                refresh
                                                                                            }
                                                                                        />
                                                                                        {isAllowed(
                                                                                            "OPERACION_ORDEN_COMPRA_CLIENTE_IMPRIMIR_PDF"
                                                                                        ) && (
                                                                                            <ModalOptionsRowOcc
                                                                                                action="estado/"
                                                                                                actionText="Visualizar PDF"
                                                                                                item={
                                                                                                    item
                                                                                                }
                                                                                                handleAction={
                                                                                                    close
                                                                                                }
                                                                                                refresh={
                                                                                                    refresh
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {/* <ModalOptionsRowOcc
                                                                                    action="previewPDF"
                                                                                    actionText="Vista previa PDF"
                                                                                    item={
                                                                                        item
                                                                                    }
                                                                                    handleAction={
                                                                                        setOptionsRow
                                                                                    }
                                                                                    refresh={
                                                                                        refresh
                                                                                    }
                                                                                /> */}
                                                                                    </>
                                                                                ),
                                                                            ordenpedidointerna:
                                                                                (
                                                                                    <>
                                                                                        <ModalOptionsRowOpi
                                                                                            action={`${ruta}/change_state/`}
                                                                                            actionText={
                                                                                                "Cambiar estado de la orden"
                                                                                            }
                                                                                            item={
                                                                                                item
                                                                                            }
                                                                                            requestEndpoint={`${ruta}/states`}
                                                                                            requestAction={
                                                                                                "cambio_estado"
                                                                                            }
                                                                                            id={
                                                                                                item.id
                                                                                            }
                                                                                            refresh={
                                                                                                refresh
                                                                                            }
                                                                                            handlePopover={
                                                                                                close
                                                                                            }
                                                                                        />
                                                                                        <ModalOptionsRowOpi
                                                                                            action={`${ruta}/return_previous_state/`}
                                                                                            actionText={
                                                                                                "Volver al estado anterior"
                                                                                            }
                                                                                            item={
                                                                                                item
                                                                                            }
                                                                                            requestAction={
                                                                                                "retorno_estado"
                                                                                            }
                                                                                            id={
                                                                                                item.id
                                                                                            }
                                                                                            refresh={
                                                                                                refresh
                                                                                            }
                                                                                            handlePopover={
                                                                                                close
                                                                                            }
                                                                                        />
                                                                                        <ModalOptionsRowOpi
                                                                                            actionText={
                                                                                                "Ver PDF en nueva ventana"
                                                                                            }
                                                                                            item={
                                                                                                item
                                                                                            }
                                                                                            id={
                                                                                                item.id
                                                                                            }
                                                                                            requestEndpoint={`${ruta}/print_rave/${item.id}`}
                                                                                            requestAction={
                                                                                                "previewPDF"
                                                                                            }
                                                                                            refresh={
                                                                                                refresh
                                                                                            }
                                                                                            handlePopover={
                                                                                                close
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                ),
                                                                            parteoperativo:
                                                                                (
                                                                                    <>
                                                                                        <button>
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                className="h-6 w-6 text-indigo-600 hover:text-indigo-900"
                                                                                                viewBox="0 0 32 32"
                                                                                            >
                                                                                                <path
                                                                                                    fill="currentColor"
                                                                                                    d="M30 18v-2h-6v10h2v-4h3v-2h-3v-2h4zm-11 8h-4V16h4a3.003 3.003 0 0 1 3 3v4a3.003 3.003 0 0 1-3 3zm-2-2h2a1.001 1.001 0 0 0 1-1v-4a1.001 1.001 0 0 0-1-1h-2zm-6-8H6v10h2v-3h3a2.003 2.003 0 0 0 2-2v-3a2.002 2.002 0 0 0-2-2zm-3 5v-3h3l.001 3z"
                                                                                                />
                                                                                                <path
                                                                                                    fill="currentColor"
                                                                                                    d="M22 14v-4a.91.91 0 0 0-.3-.7l-7-7A.909.909 0 0 0 14 2H4a2.006 2.006 0 0 0-2 2v24a2 2 0 0 0 2 2h16v-2H4V4h8v6a2.006 2.006 0 0 0 2 2h6v2Zm-8-4V4.4l5.6 5.6Z"
                                                                                                />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </>
                                                                                ),
                                                                        }[ruta]
                                                                    }
                                                                    {/* */}
                                                                </div>
                                                            )}
                                                        </Popover.Panel>
                                                    </Popover>
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </td>
                    );
                } else if (view === "multiple") {
                    return (
                        <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-3">
                            <input
                                className="rounded"
                                type="checkbox"
                                name="select-multiple"
                                checked={verifyChecked()}
                                onChange={() =>
                                    handleCheckboxOption(item)
                                }
                                value={item}
                            />
                        </td>
                    );
                } else if (view === "single") {
                    return (
                        <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-3">
                            <input
                                type="radio"
                                name="select-single"
                                checked={verifyChecked()}
                                id={JSON.stringify(item)}
                                onChange={handleRadioButtonOption}
                                value={item.id}
                            />
                        </td>
                    );
                }
            })()}
        </tr>
    );
};
