import { useRef, useState, useContext, useEffect } from "react";
import UserContext from "../../../context/UserContext";
import axios from "axios";
import ModalContext from "../../../context/ModalContext";
import AlertContext from "../../../context/AlertContext";
import { useResourcesCache } from "../../../hooks/useResourcesCache";
import localForage from 'localforage';

let ruta = 'articulolista'
export const useSelectorArticulo = (onAddArticle, listaPrecioKey = 1) => {
    const {
        user,
        url,
        workstationActive,
        headers,
        resourcePagination,
        resourceCache,
    } = useContext(UserContext);
    const { select } = useContext(ModalContext);
    const { setResponseText, setStatusCode } = useContext(AlertContext);
    const [open, setOpen] = useState(false);
    const [alerta, setAlerta] = useState("");
    const [articulos, setArticulos] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const [avanzada, setAvanzada] = useState("");
    const [loading, setLoading] = useState(false);
    const [allColumns, setAllColumns] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const cancelButtonRef = useRef(null);
    const acceptButtonRef = useRef(null);
    const filterResult = articulos.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );
    const { findResourceCache, findResourceOffset } = useResourcesCache();
    const [paginationFinish, setPaginationFinish] = useState(false);
    const [abortController, setAbortController] = useState(null);

    const valueOffset = findResourceOffset(resourcePagination, ruta);
    const isCache = findResourceCache(resourceCache, ruta);

    const getArticulos = async (offset, resourceData = []) => {
        const controller = new AbortController();
        const offsetBody = valueOffset !== -1 ? (offset === undefined ? 0 : offset) : -1;
        setAbortController(controller);
        setLoading(true);
        setOpen(true)
        setPaginationFinish(true);
        console.log(offsetBody);
        await axios
            .post(
                url + ruta + "/readall/listaprecios/" + listaPrecioKey,
                {
                    // headers: headers,
                    Workstation: workstationActive.WorkstationKey,
                    Usuario: user.UsuarioKey,
                    RequiereContexto: true,
                    Offset: offsetBody,
                },
                {
                    signal: controller.signal,
                }
            )
            .then(function (response) {
                const accumulatedData = resourceData.concat(response.data.Data);
                if (response.data.Data.length === valueOffset) {
                    getArticulos(
                        offsetBody !== -1 && offsetBody + 1,
                        accumulatedData
                    );
                } else {
                    setPaginationFinish(false);
                    if (findResourceCache(resourceCache, ruta)) {
                        let objResource = {
                            Data: accumulatedData,
                            WebForms: response.data.WebForms,
                            Acciones: response.data.Acciones,
                        };
                        localForage.setItem(ruta, objResource);
                        // localStorage.setItem(ruta, JSON.stringify(objResource));
                    }
                }
                setArticulos((data) => [...data, ...response.data.Data]);
                setLoading(false);
                setAllColumns(response.data.WebForms);
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.name === "CanceledError") {
                    if (findResourceCache(resourceCache, ruta)) {
                        localStorage.removeItem(ruta);
                    }
                    setResponseText("La carga anterior fue cancelada.");
                    setTimeout(() => {
                        setResponseText("");
                    }, 2000);
                    setStatusCode(404);
                } else {
                    if (error.response != undefined) {
                        setLoading(false);
                        if (error.response.status === 404) {
                            setResponseText(
                                "Error de petición. Consulte con administración"
                            );
                        } else {
                            setResponseText(error.response.data.Motivo);
                        }
                        setStatusCode(error.response.status);
                    } else {
                        setLoading(false);
                        setResponseText(
                            "Hubo un error crítico. Consulte con administración"
                        );
                    }
                }
                setOpen(false)
            });
    };

    // filterField;
    // queryString;
    // byStringSmart;
    const searchSmart = async (param) => {
        if (param === "" || param.trim().length <= 3) {
            setResponseText("Debe ingresar una palabra de 4 caracteres para buscar.");
            setTimeout(() => {
                setResponseText("");
            }, 2000);
            setStatusCode(404);
        } else {
            // const queryFilter = [
            //     {
            //         queryString: param,
            //         filterField: "filtered",
            //         filterType: "byStringSmart",
            //     },
            // ];
            setLoading(true);
            await axios
                .post(url + ruta + "/readall/listaprecios/" + listaPrecioKey, {
                    // headers: headers,
                    Workstation: workstationActive.WorkstationKey,
                    Usuario: user.UsuarioKey,
                    // Filtros: queryFilter,
                    BAvanzada: param,
                    RequiereContexto: true,
                    // Offset: offsetBody,
                })
                .then(function (response) {
                    setArticulos(response.data.Data);
                    setAllColumns(response.data.WebForms);
                    setLoading(false);
                    console.log(response);
                    // const accumulatedData = resourceData.concat(
                    //     response.data.Data
                    // );
                    // if (response.data.Data.length === valueOffset) {
                    //     getArticulos(
                    //         offsetBody !== -1 && offsetBody + 1,
                    //         accumulatedData
                    //     );
                    // } else {
                    //     setPaginationFinish(false);
                    //     if (findResourceCache(resourceCache, ruta)) {
                    //         let objResource = {
                    //             Data: accumulatedData,
                    //             WebForms: response.data.WebForms,
                    //             Acciones: response.data.Acciones,
                    //         };
                    //         localForage.setItem(ruta, objResource);
                    //         // localStorage.setItem(ruta, JSON.stringify(objResource));
                    //     }
                    // }
                    // setArticulos((data) => [...data, ...response.data.Data]);
                    // setLoading(false);
                    // setAllColumns(response.data.WebForms);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error.response);
                    if (error.name === "CanceledError") {
                        if (findResourceCache(resourceCache, ruta)) {
                            localStorage.removeItem(ruta);
                        }
                        setResponseText("La carga anterior fue cancelada.");
                        setTimeout(() => {
                            setResponseText("");
                        }, 2000);
                        setStatusCode(404);
                    } else {
                        if (error.response != undefined) {
                            setLoading(false);
                            if (error.response.status === 404) {
                                setResponseText(
                                    "Error de petición. Consulte con administración"
                                );
                            } else {
                                setResponseText(error.response.data.Motivo);
                            }
                            setStatusCode(error.response.status);
                        } else {
                            setResponseText(
                                "Hubo un error crítico. Consulte con administración"
                            );
                        }
                    }
                });
        }
    }

    const selectArticle = () => {
        if (select.length === 0) {
            setAlerta("Debe seleccionar un item al menos.");
        } else {
            setOpen(false);
            onAddArticle(select);
        }
    };

    const close = () => {
        setOpen(!open);
        setBusqueda("");
    };

    useEffect(() => {
        if (select.length > 0 && acceptButtonRef.current) {
            acceptButtonRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    }, [select]);

    useEffect(() => {
        setBusqueda("");

    }, [open]);

    // Cuando el controlador de la 'signal' cambia y a su vez tenia una 'signal' activa se cancela la petición
    useEffect(() => {
        return () => {
            if (abortController && open) {
                abortController.abort();
            }
        };
    }, [abortController]);

    // Cuando la ruta existe, se ejecuta la petición
    useEffect(() => {
        // if()
        // console.log('localforage,', localForage);
        if(open && !isCache){
            getArticulos();
            // if (
            //     findResourceCache(resourceCache, ruta) &&
            //     localStorage.getItem(ruta)
            // ) {
            //     let data = JSON.parse(localStorage.getItem(ruta));
            //     setArticulos(data.Data);
            //     setAllColumns(data.WebForms);
            // } else {
            //     getArticulos();
            // }
        }

        return () => {
            if(open){
                if (abortController && select) {
                    abortController.abort();
                    setAbortController(null);
                }
                setArticulos([]);
                setResponseText("");
                setBusqueda("");
                setAvanzada("");
            }
        };
    }, [open]);

    return {
        open,
        alerta,
        busqueda,
        setBusqueda,
        setOpen,
        loading,
        setAlerta,
        allColumns,
        getArticulos,
        selectArticle,
        cancelButtonRef,
        sortConfig,
        setSortConfig,
        filterResult,
        close,
        acceptButtonRef,
        paginationFinish,
        searchSmart,
        isCache,
        avanzada,
        setAvanzada,
    };
};
