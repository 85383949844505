import { useRef, useState, useContext } from "react";
import UserContext from "../../../context/UserContext";
import ModalContext from "../../../context/ModalContext";
import axios from "axios";
import AlertContext from "../../../context/AlertContext";

export const useSelectorContrato = (contratoKey, onAddArticle) => {
    const { user, url, headers, workstationActive } = useContext(UserContext);
    const { select, setSelect } = useContext(ModalContext);
    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const [open, setOpen] = useState(false);
    const [alerta, setAlerta] = useState("");
    const [busqueda, setBusqueda] = useState("");
    const [articulos, setArticulos] = useState([]);
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const cancelButtonRef = useRef(null);
    const filterResult = articulos.filter((item) =>
        item.Filtered.toLowerCase().includes(busqueda.toLowerCase())
    );

    const getArticulos = async () => {
        setOpen(true);
        setLoading(true);
        await axios
            .post(url + "articulo/readall/contratooperacion/" + contratoKey, {
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                RequiereContexto: true,
            })
            .then(function (response) {
                console.log(response);
                setLoading(false);
                setArticulos(response.data.Data);
                setColumns(response.data.WebForms);
                setSelect([]);
            })
            .catch(function (error) {
                if (error.response != undefined) {
                    setLoading(false);
                    setArticulos([]);
                    if (error.response.status === 404) {
                        setResponseText(
                            "Error de petición. Consulte con administración"
                        );
                    } else {
                        setResponseText(error.response.data.Motivo);
                    }
                    setStatusCode(error.response.status);
                } else {
                    setLoading(false);
                    setArticulos([]);
                    setResponseText(
                        "Hubo un error crítico. Consulte con administración"
                    );
                }
            });
    };

    const selectArticle = () => {
        if (select.length === 0) {
            setAlerta("Debe seleccionar un item al menos.");
        } else {
            setOpen(false);
            onAddArticle(select);
        }
    };

    return {
        open,
        alerta,
        setOpen,
        loading,
        columns,
        setAlerta,
        cancelButtonRef,
        sortConfig,
        getArticulos,
        selectArticle,
        setSortConfig,
        busqueda,
        setBusqueda,
        filterResult,
    };
};
