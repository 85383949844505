//custom hook
import { useSelectorByMoneda } from "./useSelectorByMoneda";

import { Fragment, useContext, useEffect, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'

//assets
import { TripleMaze } from "react-spinner-animated";
import ModalContext from "../../../context/ModalContext";
import UserContext from "../../../context/UserContext";
import { SelectMonedaComponent } from "./SelectMonedaComponent";
import { EquivalenciaMoneda } from "./EquivalenciaMoneda";

export const SelectorByMoneda = ({
    textButton,
    ruta,
    action,
    onHandleChange,
    onAddMoneda,
  }) => {
    const {
        open,
        data,
        monedas,
        search,
        loading,
        select,
        columns,
        messageAlert,
        getMoneda,
        setSearch,
        selectMoneda,
        setOpen,
        sortConfig,
        setSortConfig,
        handleRequestMoneda,
    } = useSelectorByMoneda(ruta, action, onHandleChange);
  
    const initialSteps = [
        {
            id: "1",
            name: "Seleccionar Moneda",
            href: "#",
            status: "current",
        },
        {
            id: "2",
            name: "Equivalencia en Pesos",
            href: "#",
            status: "upcoming",
        },
    ];
  
    const [steps, setSteps] = useState(initialSteps);
  
    const [activeStep, setActiveStep] = useState(steps[0]);
  
    const handleBack = () => {
        const index = steps.findIndex((x) => x.id === activeStep.id);
        setActiveStep(steps[index - 1]);
  
        switch (activeStep.id) {
            case "3":
                setSteps([
                    {
                        id: "1",
                        name: "Seleccionar Moneda",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Equivalencia en Pesos",
                        href: "#",
                        status: "current",
                    },
                ]);
                break;
            default:
                setSteps(initialSteps);
        }
    };   

    const handleNext = (param) => {
        // console.log(param);
        if (steps[steps.length - 1].id === activeStep.id) {
            handleRequestMoneda(param)
            setOpen(false);
        } else {
            const index = steps.findIndex((x) => x.id === activeStep.id);
            setActiveStep(steps[index + 1]);
            switch (activeStep.id) {
                case "1":
                    setSteps([
                        {
                            id: "1",
                            name: "Seleccionar Moneda",
                            href: "#",
                            status: "complete",
                        },
                        {
                            id: "2",
                            name: "Equivalencia en Pesos",
                            href: "#",
                            status: "current",
                        },
                    ]);
                    break;
                default:
                    setSteps(initialSteps);
                    break;
            }
        }
    };

    useEffect(() => {
        if (!open) {
            setActiveStep(steps[0]);
            setSteps(initialSteps);
        }
    }, [open]);
  
    const closeSelectorContrato = () => {
        setOpen(!open);
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    
    return (
        <>
            <button
                onClick={getMoneda}
                className="inline-flex items-center text-sm font-medium text-blue-600 hover:underline"
            >
                {textButton}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    />
                </svg>
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="md:flex items-end justify-center p-4 text-center sm:items-start sm:p-0 sm:mt-5">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="bg-white h-full md:max-h-full opacity-100 overflow-auto pb-10 px-4 py-5 relative rounded-lg shadow-xl sm:p-6 sm:scale-100 sm:w-5/6 text-left transform transition-all translate-y-0">
                                    <div className="absolute top-0 right-0 pt-5 pr-6 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={closeSelectorContrato}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>

                                    {/* <Alert /> */}
                                    <nav aria-label="Progress" className="mt-8">
                                        <ul className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 hidden">
                                            {steps.map((step, stepIdx) => (
                                                <li
                                                    key={step.name}
                                                    className="relative md:flex md:flex-1"
                                                >
                                                    {step.status ===
                                                    "complete" ? (
                                                        <div className="group flex w-full items-center">
                                                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                                                                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-6 w-6 text-white"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="ml-4 text-sm font-medium text-gray-900">
                                                                    {step.name}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ) : step.status ===
                                                      "current" ? (
                                                        <div className="flex items-center px-6 py-4 text-sm font-medium">
                                                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                                                <span className="text-indigo-600">
                                                                    {step.id}
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 text-sm font-medium text-indigo-600">
                                                                {step.name}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="group flex items-center">
                                                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                                                                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                                                    <span className="text-gray-500 group-hover:text-gray-900">
                                                                        {
                                                                            step.id
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                                    {step.name}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    )}

                                                    {stepIdx !==
                                                    steps.length - 1 ? (
                                                        <>
                                                            {/* Arrow separator for lg screens and up */}
                                                            <div
                                                                className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                                                aria-hidden="true"
                                                            >
                                                                <svg
                                                                    className="h-full w-full text-gray-300"
                                                                    viewBox="0 0 22 80"
                                                                    fill="none"
                                                                    preserveAspectRatio="none"
                                                                >
                                                                    <path
                                                                        d="M0 -2L20 40L0 82"
                                                                        vectorEffect="non-scaling-stroke"
                                                                        stroke="currentcolor"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </li>
                                            ))}
                                        </ul>
                                        {/* steps para movil */}
                                        <ol
                                            role="list"
                                            className="flex items-center md:hidden justify-center"
                                        >
                                            {steps.map((step, stepIdx) => (
                                                <li
                                                    key={step.name}
                                                    className={classNames(
                                                        stepIdx !==
                                                            steps.length - 1
                                                            ? "pr-8 sm:pr-20"
                                                            : "",
                                                        "relative"
                                                    )}
                                                >
                                                    {step.status ===
                                                    "complete" ? (
                                                        <>
                                                            <div
                                                                className="absolute inset-0 flex items-center"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="h-0.5 w-full bg-indigo-600" />
                                                            </div>
                                                            <a
                                                                href="#"
                                                                className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6 text-white"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                    />
                                                                </svg>
                                                                <span className="sr-only">
                                                                    {step.name}
                                                                </span>
                                                            </a>
                                                        </>
                                                    ) : step.status ===
                                                      "current" ? (
                                                        <>
                                                            <div
                                                                className="absolute inset-0 flex items-center"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="h-0.5 w-full bg-gray-200" />
                                                            </div>
                                                            <a
                                                                href="#"
                                                                className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                                                                aria-current="step"
                                                            >
                                                                <span
                                                                    className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="sr-only">
                                                                    {step.name}
                                                                </span>
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="absolute inset-0 flex items-center"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="h-0.5 w-full bg-gray-200" />
                                                            </div>
                                                            <a
                                                                href="#"
                                                                className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                                                            >
                                                                <span
                                                                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="sr-only">
                                                                    {step.name}
                                                                </span>
                                                            </a>
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                        </ol>
                                        <p className="md:hidden block text-sm text-gray-600 w-full text-center my-5">
                                            {activeStep.name}
                                        </p>
                                    </nav>

                                    {loading ? (
                                        <div className="mt-52 flex-col h-full inline-flex items-center justify-center mx-auto space-y-5 w-full">
                                            <div className="-mt-52 w-20 h-20 rounded-full animate-spin border-x-8 border-solid border-green-600 border-t-transparent shadow-md"></div>
                                            <p className="text-center">
                                                Cargando . . .
                                            </p>
                                        </div>
                                    ) : activeStep.id === "1" ? (
                                        <SelectMonedaComponent
                                            monedas={monedas}
                                            selectMoneda={selectMoneda}
                                            columns={columns}
                                            nextStep={handleNext}
                                            sortConfig={sortConfig}
                                            setSortConfig={setSortConfig}
                                        />
                                    ) : (
                                        activeStep.id === "2" && (
                                            <EquivalenciaMoneda
                                                monedas={monedas}
                                                prevStep={handleBack}
                                                nextStep={handleNext}
                                            />
                                        )
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
