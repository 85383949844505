import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../context/UserContext";
import AlertContext from "../../../context/AlertContext";

export default function ModalDelete({ id, ruta, refresh, obj }) {
    const [open, setOpen] = useState(false);
    const { user, workstationActive, url } = useContext(UserContext);
    const { setStatusCode, setResponseText } = useContext(AlertContext);

    const handleDelete = async (e) => {
        e.preventDefault();
        await axios({
                method: 'delete',
                url:url + ruta + "/delete/" + id,
                data: {
                    Workstation: workstationActive.WorkstationKey,
                    Usuario: user.UsuarioKey
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setOpen(false);
                    setStatusCode(response.status);
                    setResponseText("Se eliminó el registro éxitosamente.");
                    setTimeout(() => {
                        setResponseText("");
                    }, 5000);
                    refresh();
                }
                // console.log(response);
            })
            .catch(function (error) {
                console.log(error.response);
                setStatusCode(error.response.status);
                if (error.response.data && "Motivo" in error.response.data) {
                    setOpen(false);
                    setResponseText(error.response.data["Motivo"]);
                    setTimeout(() => {
                        setResponseText("");
                    }, 5000);
                }
            });
    };

    // console.log(obj);

    const titleDescription = () => {
        switch (ruta) {
            case "ordencompracliente":
                return `Eliminar la orden de compra Nº ${obj.Numero}`;

            case "parteoperativo":
                return `Eliminar el parte operativo Fecha ${Moment(
                    obj.FechaParte
                ).format("DD-MM-YYYY")}`;

            case "presupuestocliente":
                return `Eliminar el presupuesto Nº ${obj.Numero}`;
            
            case "ordenpedidointerna":
                return `Eliminar la orden de pedido Nº ${obj.Numero}`;

            default:
                return `Eliminar el registro ${obj.Nombre} (${obj.Codigo})`;
        }
    };

    // console.log(obj);
    return (
        <>
            <div className="tooltip">
                <button
                    onClick={() => setOpen(!open)}
                    className="text-indigo-600 hover:text-indigo-900"
                >
                    <span className="tooltiptext">Eliminar</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                    </svg>
                </button>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="md:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <form onSubmit={handleDelete}>
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6 text-red-600"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="mt-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                    {titleDescription}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        ¿Está seguro que desea
                                                        eliminar esta operación?
                                                        Esta acción no podrá ser
                                                        reversible.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse space-y-2">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 hover:bg-red-700 px-4 py-2 text-base font-medium text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                                            >
                                                Eliminar
                                            </button>
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
