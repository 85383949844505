import { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../../../context/UserContext";
import ModalContext from "../../../context/ModalContext";
import AlertContext from "../../../context/AlertContext";

//assets
import { Dialog, Transition } from "@headlessui/react";
import { TripleMaze } from "react-spinner-animated";

//components
import {
    FirstComponent,
    SecondComponent,
    ThirdComponent,
    FourthComponent,
} from "../../StepWizard/OrdenTrabajoInterna";
import { Alert } from "../../Alerts/Alert";

export const EditModalOrdenTrabajoInterna = ({ id, ruta, refresh, acciones }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [select, setSelect] = useState([]);
    const [loading, setLoading] = useState(false);
    const [caracteristicasVariantes, setCaracteristicasVariantes] = useState(
        []
    );
    const [keyUnlock, setKeyUnlock] = useState();

    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const { user, workstationActive, headers, url } = useContext(UserContext);

    const openEdit = async () => {
        await axios
            .post(url + ruta + "/read/" + id, {
                Usuario: user.UsuarioKey,
                Workstation: workstationActive.WorkstationKey,
            })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log(response.data);
                    setOpen(true);
                    setCaracteristicasVariantes(
                        response.data.CaracteristicasVariantes
                    );
                    setKeyUnlock(response.data.keyunlocks);
                    setData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error.response);
                setStatusCode(error.response.status);
                if (error.response.data && "Motivo" in error.response.data) {
                    setResponseText(error.response.data["Motivo"]);
                    setTimeout(() => {
                        setResponseText("");
                    }, 5000);
                }
            });
    };

    const openNew = async () => {
        await axios
            .post(url + ruta + "/new", {
                Usuario: user.UsuarioKey,
                Workstation: workstationActive.WorkstationKey,
            })
            .then(function (response) {
                console.log(response.data);
                setOpen(true);
                setData(response.data);
                setCaracteristicasVariantes(
                    response.data.CaracteristicasVariantes
                );
            })
            .catch(function (error) {
                setStatusCode(error.response.status);
                if (error.response.data && "Motivo" in error.response.data) {
                    setResponseText(error.response.data["Motivo"]);
                    setTimeout(() => {
                        setResponseText("");
                    }, 5000);
                }
            });
    };

    const unblockOperation = async () => {
        if (!keyUnlock) {
            setOpen(!open);
        } else {
            await axios
                .post(url + ruta + `/unlock/${keyUnlock}`, {
                    Usuario: user.UsuarioKey,
                    Workstation: workstationActive.WorkstationKey,
                    [ruta]: data,
                })
                .then(function (response) {
                    // console.log(response.data);
                    setOpen(!open);
                })
                .catch(function (error) {
                    setStatusCode(error.response.status);
                    if (error.response.data && "Motivo" in error.response.data) {
                        setResponseText(error.response.data["Motivo"]);
                        setTimeout(() => {
                            setResponseText("");
                        }, 5000);
                    }
                });
        }
    };

    const initialSteps = [
        {
            id: "1",
            name: "Datos de la orden",
            href: "#",
            status: "current",
        },
        {
            id: "2",
            name: "Articulos",
            href: "#",
            status: "upcoming",
        },
        {
            id: "3",
            name: "Caracteristicas",
            href: "#",
            status: "upcoming",
        },
        {
            id: "4",
            name: "Datos adicionales",
            href: "#",
            status: "upcoming",
        },
    ];

    const [steps, setSteps] = useState(initialSteps);

    const [activeStep, setActiveStep] = useState(steps[0]);

    const handleBack = () => {
        const index = steps.findIndex((x) => x.id === activeStep.id);
        setActiveStep(steps[index - 1]);

        switch (activeStep.id) {
            case "3":
                setSteps([
                    {
                        id: "1",
                        name: "Datos de la orden",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Articulos",
                        href: "#",
                        status: "current",
                    },
                    {
                        id: "3",
                        name: "Caracteristicas",
                        href: "#",
                        status: "upcoming",
                    },
                    {
                        id: "4",
                        name: "Datos adicionales",
                        href: "#",
                        status: "upcoming",
                    },
                ]);
                break;
            case "4":
                setSteps([
                    {
                        id: "1",
                        name: "Datos de la orden",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Articulos",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "3",
                        name: "Caracteristicas",
                        href: "#",
                        status: "current",
                    },
                    {
                        id: "4",
                        name: "Datos adicionales",
                        href: "#",
                        status: "upcoming",
                    },
                ]);
                break;
            default:
                setSteps(initialSteps);
        }
    };

    const handleNext = () => {
        //si llega al ultimo paso, post o put dependiendo si tiene operacionKey
        if (steps[steps.length - 1].id === activeStep.id) {
            setLoading(true);
            console.log(data);
            // console.log(data.Operacion.OperacionKey);
            if (data.Operacion.OperacionKey === 0) {
                axios
                    .post(url + ruta + "/create", {
                        Workstation: workstationActive.WorkstationKey,
                        Usuario: user.UsuarioKey,
                        [ruta]: data,
                        // headers: headers,
                    })
                    .then(function (response) {
                        // console.log(response);
                        setStatusCode(response.status);
                        setResponseText("Se creó con éxito la operación.");
                        setTimeout(() => {
                            setResponseText("");
                        }, 3000);
                        setLoading(false);
                        setOpen(false);
                        setActiveStep(steps[0]);
                        refresh();
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        setLoading(false);
                        setStatusCode(error.response.status);
                        if (error.response.data && "Motivo" in error.response.data) {
                            setResponseText(error.response.data["Motivo"]);
                            setTimeout(() => {
                                setResponseText("");
                            }, 3000);
                        }
                    });
            } else {
                // console.log(keyUnlock);
                axios
                    .post(url + ruta + `/update/${keyUnlock}`, {
                        Workstation: workstationActive.WorkstationKey,
                        Usuario: user.UsuarioKey,
                        [ruta]: data,
                    })
                    .then(function (response) {
                        // console.log(response);
                        setLoading(false);
                        setStatusCode(response.status);
                        setOpen(false);
                        setActiveStep(steps[0]);
                        setResponseText(
                            "Se editó correctamente la operación seleccionada."
                        );
                        setTimeout(() => {
                            setResponseText("");
                        }, 3000);
                        refresh();
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        setLoading(false);
                        setStatusCode(error.response.status);
                        if (error.response.data && "Motivo" in error.response.data) {
                            setResponseText(error.response.data["Motivo"]);
                            setTimeout(() => {
                                setResponseText("");
                            }, 3000);
                        }
                    });
            }

            return;
        }

        const index = steps.findIndex((x) => x.id === activeStep.id);
        setActiveStep(steps[index + 1]);
        switch (activeStep.id) {
            case "1":
                setSteps([
                    {
                        id: "1",
                        name: "Datos de la orden",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Articulos",
                        href: "#",
                        status: "current",
                    },
                    {
                        id: "3",
                        name: "Caracteristicas",
                        href: "#",
                        status: "upcoming",
                    },
                    {
                        id: "4",
                        name: "Datos adicionales",
                        href: "#",
                        status: "upcoming",
                    },
                ]);
                break;
            case "2":
                setSteps([
                    {
                        id: "1",
                        name: "Datos de la orden",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Articulos",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "3",
                        name: "Caracteristicas",
                        href: "#",
                        status: "current",
                    },
                    {
                        id: "4",
                        name: "Datos adicionales",
                        href: "#",
                        status: "upcoming",
                    },
                ]);
                break;
            case "3":
                setSteps([
                    {
                        id: "1",
                        name: "Datos de la orden",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "2",
                        name: "Articulos",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "3",
                        name: "Caracteristicas",
                        href: "#",
                        status: "complete",
                    },
                    {
                        id: "4",
                        name: "Datos adicionales",
                        href: "#",
                        status: "current",
                    },
                ]);
                break;
            default:
                setSteps(initialSteps);
                break;
        }
    };

    useEffect(() => {
        if (!open) {
            setActiveStep(steps[0]);
            setSteps(initialSteps);
        }
    }, [open]);

    return (
        <>
            {id ? (
                <div className="tooltip">
                    <button
                        onClick={openEdit}
                        className="text-indigo-600 hover:text-indigo-900"
                    >
                        <span className="tooltiptext">Editar</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                        </svg>
                    </button>
                </div>
            ) : (
                <button
                    className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800"
                    onClick={openNew}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-7 h-7 mr-2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    <span className="md:block hidden">Nuevo</span>
                </button>
            )}

            <ModalContext.Provider
                value={{
                    select,
                    setSelect,
                    data,
                    setData,
                    acciones,
                    caracteristicasVariantes,
                    setCaracteristicasVariantes,
                }}
            >
                <Transition.Root show={open} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={unblockOperation}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex items-end justify-center p-4 text-center sm:items-start sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 text-left shadow-xl transition-all sm:w-full sm:max-w-6xl sm:p-6  ">
                                        <div className="absolute top-0 right-0 hidden pt-5 pr-6 sm:block">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={unblockOperation}
                                            >
                                                <span className="sr-only">
                                                    Close
                                                </span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="sm:flex sm:justify-between sm:items-center mb-1">
                                            <div className="text-center sm:mt-0 sm:text-center">
                                                <p className="text-medium font-bold leading-7 text-gray-800 text-center">
                                                    {id
                                                        ? `Editar orden de trabajo interna
                                                                Nº ${data.Numero}`
                                                        : `Nueva orden de trabajo interna`}
                                                </p>
                                            </div>
                                        </div>
                                        <Alert />
                                        <nav aria-label="Progress">
                                            <ul className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                                                {steps.map((step, stepIdx) => (
                                                    <li
                                                        key={step.name}
                                                        className="relative md:flex md:flex-1"
                                                    >
                                                        {step.status ===
                                                        "complete" ? (
                                                            <div className="group flex w-full items-center">
                                                                <span className="flex items-center px-6 py-4 text-sm font-medium">
                                                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6 text-white"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span className="ml-4 text-sm font-medium text-gray-900">
                                                                        {
                                                                            step.name
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        ) : step.status ===
                                                          "current" ? (
                                                            <div className="flex items-center px-6 py-4 text-sm font-medium">
                                                                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                                                    <span className="text-indigo-600">
                                                                        {
                                                                            step.id
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className="ml-4 text-sm font-medium text-indigo-600">
                                                                    {step.name}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="group flex items-center">
                                                                <span className="flex items-center px-6 py-4 text-sm font-medium">
                                                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                                                        <span className="text-gray-500 group-hover:text-gray-900">
                                                                            {
                                                                                step.id
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                                        {
                                                                            step.name
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )}

                                                        {stepIdx !==
                                                        steps.length - 1 ? (
                                                            <>
                                                                {/* Arrow separator for lg screens and up */}
                                                                <div
                                                                    className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                                                    aria-hidden="true"
                                                                >
                                                                    <svg
                                                                        className="h-full w-full text-gray-300"
                                                                        viewBox="0 0 22 80"
                                                                        fill="none"
                                                                        preserveAspectRatio="none"
                                                                    >
                                                                        <path
                                                                            d="M0 -2L20 40L0 82"
                                                                            vectorEffect="non-scaling-stroke"
                                                                            stroke="currentcolor"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>

                                        <div className="md:h-96 max-h-full h-full">
                                            {loading ? (
                                                <TripleMaze
                                                    centered={true}
                                                    width={"100px"}
                                                    height={"200px"}
                                                    text={"Espere..."}
                                                />
                                            ) : activeStep.id === "1" ? (
                                                <FirstComponent
                                                    ruta={ruta}
                                                    nextStep={handleNext}
                                                />
                                            ) : activeStep.id === "2" ? (
                                                <SecondComponent
                                                    nextStep={handleNext}
                                                    prevStep={handleBack}
                                                />
                                            ) : activeStep.id === "3" ? (
                                                <ThirdComponent
                                                    nextStep={handleNext}
                                                    prevStep={handleBack}
                                                />
                                            ) : (
                                                <FourthComponent
                                                    nextStep={handleNext}
                                                    prevStep={handleBack}
                                                />
                                            )}
                                        </div>
                                        {/* <div className="flex w-full justify-between">
                                            <div className="inline-flex items-center w-56 px-3 mr-10 py-2 h-10 bg-gray-200 border-2 border-black">
                                                <p className="text-xs mr-3">
                                                    Subtotal:
                                                </p>
                                                <p className="text-xl font-bold text-gray-900">
                                                    $
                                                    {data === null
                                                        ? "0"
                                                        : data.Operacion &&
                                                          data.Operacion
                                                              .Subtotal}
                                                </p>
                                            </div>
                                            <div className="inline-flex items-center w-56 px-3 mr-10 py-2 h-10 bg-orange-200 border-2 border-black">
                                                <p className="text-xs mr-3">
                                                    Monto IVA:
                                                </p>
                                                <p className="text-xl font-bold text-gray-900">
                                                    $
                                                    {data === null
                                                        ? "0"
                                                        : data.Operacion &&
                                                          data.Operacion
                                                              .MontoIVA}
                                                </p>
                                            </div>
                                            <div className="inline-flex items-center w-56 px-3 mr-10 py-2 h-10 bg-gray-200 border-2 border-black">
                                                <p className="text-xs mr-3">
                                                    Total:
                                                </p>
                                                <p className="text-xl font-bold text-gray-900">
                                                    $
                                                    {data === null
                                                        ? "0"
                                                        : data.Operacion &&
                                                          data.Operacion
                                                              .ImporteTotal}
                                                </p>
                                            </div>
                                        </div> */}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </ModalContext.Provider>
        </>
    );
};
